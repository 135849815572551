import { createApp } from '@/store/common/pal';
import { hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { remoteDesktopStore } from '@/store/remoteDesktop/store';

import { actions } from './actions';
import { model } from './model';

const nap = ({ proposedState }) => {
   hpcSubmissionStore.actions.setInitialTemplate(proposedState.templates);
   remoteDesktopStore.actions.safeSetTemplate(proposedState.templates);

   return proposedState;
};

export const jobTypesStore = createApp(actions, model, nap);
