import { pathEq } from 'ramda';

import { dataStatePath, dataStates } from '@/utils/constants';

import services from './services';

const hasMigrationError = pathEq('RCT_RCTTenantMigrationInProgressError', [0, 'extensions', 'serviceError', 'code']);

export const rawActions = (present) => ({
   loadCredits: (client, poll = false) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getUserBalance(client)
         .then((credits) => {
            present({ credits, dataState: dataStates.ready }, { client, poll });
         })
         .catch((e) =>
            present(
               {
                  op: 'replace',
                  value: hasMigrationError(e) ? dataStates.migrating : dataStates.error,
                  path: dataStatePath,
               },
               { client, poll },
            ),
         );
   },
   test: {
      initCreditState: (data) => present({ op: 'replace', value: data, path: '' }),
   },
});
