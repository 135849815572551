/**
 * This file is used to define the remote modules that will be loaded by the application.
 * Microfrontend developers (i.e. Simcenter X App developers) should add their remote modules here.
 */
import dev from './envs/dev.js';
import prd from './envs/prd.js';
import stg from './envs/stg.js';

export default (function () {
   switch (import.meta.env.VITE_DEPLOYMENT_TYPE) {
      case 'prd':
         return prd;
      case 'stg':
         return stg;
      default:
         return dev;
   }
})();
