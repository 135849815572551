import { hasPath, ifElse, isNotEmpty, lift, mergeDeepRight, path, prop, propEq, propSatisfies, reject } from 'ramda';

import { createApp } from '@/store/common/pal';
import { filterFiles } from '@/store/files/fileUtils';

import { isNilOrEmpty } from '@/utils/comparators';

import { actions } from './actions';
import { allFilesWithRepoId } from './fileUtils';
import { model } from './model';

const applyFileFilter = lift(filterFiles)(prop('searchTerm'), prop('files'));
const filterFilesOrReset = ifElse(propSatisfies(isNilOrEmpty, 'searchTerm'), prop('files'), applyFileFilter);

const getReadyFiles = ({ proposal, proposedState }) => {
   const inputFileRepoPresent = hasPath(['fileRepository', 'input'], proposal);
   const outputFileRepoPresent = hasPath(['fileRepository', 'output'], proposal);

   const inputFileRepo = path(['fileRepository', 'input'], proposedState);
   const outputFileRepo = path(['fileRepository', 'output'], proposedState);
   if (inputFileRepoPresent || outputFileRepoPresent) {
      if (isNotEmpty(inputFileRepo.files) || isNotEmpty(outputFileRepo.files)) {
         return mergeDeepRight(proposedState, { files: allFilesWithRepoId(inputFileRepo, outputFileRepo) });
      }
   }
   return proposedState;
};

const nap = ({ proposal, proposedState }) => {
   proposedState = getReadyFiles({ proposal, proposedState });
   const filtered = filterFilesOrReset(proposedState);
   const withoutConfig = reject(propEq('.config.json', 'filename'), filtered);

   return { ...proposedState, filtered: withoutConfig };
};

export const fileStore = createApp(actions, model, nap);
