import { equals, ifElse } from 'ramda';
import { useTranslation } from 'react-i18next';

import { buildJobActionsClickHandler } from '@/utils/buildJobActionsClickHandler';
import { isPendingOrQueued, isStatusTerminating } from '@/utils/comparators';
import { quickActions } from '@/utils/constants';
import { getActions, getRemoteDetailsActions } from '@/utils/jobUtils';

import { SwwcButton } from '@/swwc';

export const DetailsActions = ({ job }) => {
   const { t } = useTranslation();
   const onClick = buildJobActionsClickHandler(job);

   return (
      <div className="flex gap-4">
         {getActions(job).map(({ icon, id }) => (
            <SwwcButton
               key={`action-${id}`}
               label={isStatusTerminating(job) ? `${t('status.TERMINATING')}...` : t(`commands.${id}`)}
               disabled={isStatusTerminating(job)}
               icon={icon}
               size="md"
               onClickEvent={() => onClick(id)}
            />
         ))}
      </div>
   );
};

export const RemoteDetailsActions = ({ job }) => {
   const { t } = useTranslation();
   const onClick = buildJobActionsClickHandler(job);

   const isDisabled = ifElse(
      equals(quickActions.connect.id),
      () => isPendingOrQueued(job),
      () => isStatusTerminating(job),
   );

   return (
      <div className="flex gap-4">
         {getRemoteDetailsActions(job).map(({ icon, id, variant }) => (
            <SwwcButton
               key={`action-${id}`}
               label={isStatusTerminating(job) ? `${t('status.TERMINATING')}...` : t(`commands.${id}`)}
               disabled={isDisabled(id)}
               icon={icon}
               size="md"
               onClickEvent={() => onClick(id)}
               variant={variant ?? 'base'}
            />
         ))}
      </div>
   );
};
