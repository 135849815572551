import { gql } from '@urql/core';

export default gql`
   mutation SubmitJob($input: IdInput!) {
      submitJob(input: $input) {
         job {
            id
            name
            status
            resourceId
            submissionTime
            jobDefinition {
               jobType
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
            }
            tasks {
               id
               name
               status
               unifiedStatus
               inputFileRepository {
                  id
               }
            }
         }
      }
   }
`;
