import { useTranslation } from 'react-i18next';

const getIndices = (page, total, perPage) => {
   const startIndex = perPage * (page - 1);
   const endIndex = Math.min(perPage + startIndex, total);

   return [startIndex + 1, endIndex];
};

export const Summary = ({ page, totalEntries, entriesPerPage }) => {
   const { t } = useTranslation();
   const [start, end] = getIndices(page, totalEntries, entriesPerPage);

   return <p>{t('common.summary', { start, end, total: totalEntries })}</p>;
};
