/**
 * Functions for using PKCE in the authorization_code login
 * flow
 * https://www.rfc-editor.org/rfc/rfc7636
 *
 * PKCE makes single page apps (public clients) more secure by preventing an interception attack
 * to steal the authorization code returned by SAM (or any oauth provider).
 *
 * The client creates a random secret ( the verifier ) and derives a transformed version (the challenge)
 * When making the initial authorization_code request, the client sends the challenge.
 *
 * Once the auth server responds (by redirecting back to the client), the client requests a token
 * and sends the verifier in the request.
 *
 * The auth server will transform the verifier and compare it to the challenge
 * (which it stored from the initial request)
 */
import { compose, curry } from 'ramda';

// Session storage should be used as this expires when the page session terminates
export const retrieveVerifier = (ss) => ss.getItem('code_verifier');
export const storeVerifier = curry((ss, verifier) => ss.setItem('code_verifier', verifier));

/**
 * Convert a string to base64 and url encode
 * https://tools.ietf.org/html/rfc4648#section-5
 * @param {*} str
 * @returns
 */
function base64URLEncode(str) {
   const b64 = btoa(str);
   return b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

/**
 * Convert an ArrayBuffer to a base64 and url encoded string
 * @param {*} hash
 * @returns
 */
const bufferToBase64UrlEncoded = compose(
   base64URLEncode,
   (nums) => String.fromCharCode(...nums),
   Array.from,
   (hash) => new Uint8Array(hash),
);

/**
 * A code verifier is a cryptographic random string between 43 and 128 characters
 */
export const generateCodeVerifier = compose(
   base64URLEncode,
   (array) => String.fromCharCode.apply(null, Array.from(array)),
   (crypto) => crypto.getRandomValues(new Uint8Array(32)),
);

/**
 * Generate a code challenge from a verifier.
 * The challenge is a base64 url-encoded string of the SHA256 hash of the verifier
 * @param {*} crypto
 * @returns
 */
export const generateChallenge = (crypto) => (str) => {
   return crypto.subtle.digest({ name: 'SHA-256' }, new TextEncoder().encode(str)).then(bufferToBase64UrlEncoded);
};
