import { gql } from '@urql/core';

export default gql`
   query GetUserBalance {
      accountingCreditBalance {
         user {
            creditBalance
            effectiveBalance
         }
         account {
            creditBalance
         }
      }
   }
`;
