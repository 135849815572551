// @ts-nocheck
import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router';

import { store } from '@/store/listing/state';

import { hasResourceId, isStatusDeleting } from '@/utils/comparators';
import { goToDetails } from '@/utils/jobUtils';

import { SwwcCheckbox } from '@/swwc';

/**
 * Renders the table body with rows and interactive cells for job selection.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array<Object>} props.selectedJobs - List of selected jobs.
 * @param {Object} props.table - The table instance from `useReactTable`.
 * @returns {JSX.Element} The rendered table body with rows.
 */
export const TBody = ({ selectedJobs, table }) => {
   const navigate = useNavigate();
   return (
      <tbody data-cy="jobTable">
         {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => goToDetails(navigate)(row?.original)} className="cursor-pointer">
               {row.getVisibleCells().map((cell) => {
                  return (
                     <td
                        key={cell.id}
                        data-accessor={cell.column.id}
                        className="break-all"
                        onClick={(e) =>
                           cell.column.columnDef.header === 'select' || cell.column.columnDef.header === 'quickActions'
                              ? e.stopPropagation()
                              : null
                        }
                        title={cell.column.id === 'name' ? cell.getValue() : null}
                     >
                        {cell.column.columnDef.header === 'select' ? (
                           <SwwcCheckbox
                              key={`checkbox-${row.original.resourceId}`}
                              onCheckChanged={() => store.actions.selectJob(row.original)}
                              checked={hasResourceId(row.original.resourceId)(selectedJobs)}
                              id={row.original.resourceId}
                              disabled={isStatusDeleting(row.original)}
                           />
                        ) : (
                           flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                     </td>
                  );
               })}
            </tr>
         ))}
      </tbody>
   );
};
