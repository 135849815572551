import { useTranslation } from 'react-i18next';

import { isInsufficientError } from '@/utils/comparators';

import { SwwcButton, SwwcModalDialog } from '@/swwc';

export const InsufficientCreditsModal = ({ error, onClose }) => {
   const { t } = useTranslation();

   if (!isInsufficientError(error)) {
      return null;
   }

   const jobType = t(`jobTypeName.${error.jobType}`);

   return (
      <SwwcModalDialog
         dialogTitle={t('modal.InsufficientCreditsError.title', { jobType })}
         isModal
         opened
         type="danger"
         className="absolute whitespace-pre"
         onOpenedChange={onClose}
      >
         <p>
            {t('modal.InsufficientCreditsError.message', {
               jobType,
               currentBalance: error.parameters.credit_balance,
               minimumBalance: error.parameters.min_bal,
            })}
         </p>
         <SwwcButton slot="actions" label={t('commands.close')} onClickEvent={onClose} />
      </SwwcModalDialog>
   );
};
