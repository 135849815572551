// @ts-nocheck
import { always, compose, cond, equals, find, gt, ifElse, isNil, isNotNil, on, path, propEq, T, trim } from 'ramda';

export const updateTrimmedValue = (fn) => compose(fn, trim, path(['target', 'value']));
export const updateValue = (fn) => compose(fn, path(['detail', 'value']));
export const findComputeTypeByName = (computeTypes) => (name) => find(propEq(name, 'name'), computeTypes ?? []);
export const findSubmissionTemplateById = (templates) => (id) => find(propEq(id, 'id'), templates ?? []);

export const isIntEqual = on(equals, parseInt);

export const getMessageKey = (selected) =>
   cond([
      [isNil, always(null)],
      [equals(0), always('version.unknown')],
      [isIntEqual(selected), always('version.match')],
      [gt(selected), always('version.upgrade')],
      [T, always(null)],
   ]);

export const handleRadioLabel = ifElse(isIntEqual, always('version.radio.match'), always(null));

export const isVersionDisabled = (version, fileVersion) => isNotNil(fileVersion) && version < parseInt(fileVersion);
export const isPrecisionDisabled = (version, supportedPrecisions, precision) =>
   isNotNil(version) && !supportedPrecisions.includes(precision);
