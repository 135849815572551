import { dataStates } from '@/utils/constants';

/**
 * @typedef {[{
 *          version: string,
 *          type: string,
 *          typeHint: string,
 *          precision: Array<string>
 *          marketingVersion: number
 * }]} applicationList
 */

/**
 * An application for a given job type is a mapping from version
 * to an array of precisions
 * @typedef {{
 *    [jobType: string]: applicationList
 * }} jobTypeApplications
 */

/**
 * @type {{
 *    dataState: string,
 *    templates: Object,
 *    applications: jobTypeApplications,
 *    mappings: Object
 * }}
 */
export const model = {
   dataState: dataStates.loading,
   templates: null,
   applications: null,
   mappings: null,
};
