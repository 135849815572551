import { DigitalProductExperience } from './DigitalProductExperience';
import { ProductExcellenceProgram } from './ProductExcellenceProgram';

export function Settings() {
   return (
      <>
         <DigitalProductExperience />
         <ProductExcellenceProgram />
      </>
   );
}
