import React from 'react';

import { SwwcInformationBar } from '@/swwc';

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
   }

   componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
         error,
         errorInfo,
      });
      // You can also log error messages to an error reporting service here
   }

   render() {
      if (this.state.error) {
         // You can render any custom fallback UI
         return (
            <SwwcInformationBar
               closable={false}
               label=""
               buttonText=""
               fullWidthMode={false}
               informationText="Oops! There was an error rendering this component"
               useIcon={true}
               variant="error"
            />
         );
      }
      return this.props.children;
   }
}

// eslint-disable-next-line react/display-name
export const withErrorBoundary = (Wrapped) => (props) => (
   <ErrorBoundary>
      <Wrapped {...props} />
   </ErrorBoundary>
);
