import { dataStates, lineHeight } from '@/utils/constants';

const Node = ({ line, text }) => {
   return (
      <div
         style={{
            lineHeight: `${lineHeight}px`,
            height: lineHeight,
            top: `${line * lineHeight}px`,
         }}
         className="absolute pl-4"
      >
         <p className="relative dark:text-black-50 overflow-x-visible whitespace-pre">{text}</p>
      </div>
   );
};

export const Nodes = ({ nodes, startCursor, dataState }) => {
   if (!nodes.length && dataState === dataStates.ready) {
      return <span className="m-4">No logs to display</span>;
   }
   return nodes.map((node, i) => {
      const lineNo = startCursor + i + 1;
      return <Node line={lineNo} key={lineNo} text={node} />;
   });
};
