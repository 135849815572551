import { useTranslation } from 'react-i18next';

import { steps } from '@/utils/constants';
import { getStepLabel, getStepTenses } from '@/utils/jobStatusUtils';

import { SwwcProgressStepper, SwwcProgressStepperItem } from '@/swwc';

export const StepProgress = ({ job, currentStepLabel, currentStepIndex }) => {
   const { t } = useTranslation();
   if (currentStepIndex < 0) {
      return null;
   }

   return (
      <div className="w-full row-start-4 col-start-1 col-end-5">
         <SwwcProgressStepper currentStep={currentStepLabel} type="overflow" nonInteractive>
            {steps.map((step, i) => {
               const stepLabel = getStepLabel(job)(step);
               const text = t(`steps.${getStepTenses(i, currentStepIndex)}.${step}`);
               return (
                  <SwwcProgressStepperItem key={`step-${step}-${i}`} label={text} value={step} data-cy={stepLabel} />
               );
            })}
         </SwwcProgressStepper>
      </div>
   );
};
