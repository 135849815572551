import { useEffect, useState } from 'react';

import { errors } from '@/store/listing/errors';

import { Toast } from '@/modules/common/Toast';

export const ToastHandler = ({ item }) => {
   const [toast, setToast] = useState(null);

   useEffect(() => {
      if (!toast && item) {
         setToast({
            type: item.type,
            description: item.message,
         });
         errors.clearLastError();
      }
   }, [toast, item]);

   return toast ? <Toast toast={toast} variant="error" clearToast={() => setToast(null)} /> : null;
};
