import { fileStore } from '@/store/files/store';

import { isEmbedded, sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { getDownloadProgress, getDownloadStatus } from '@/utils/embeddedUtils';

import { SwwcButton, SwwcLoadingIndicator } from '@/swwc';

/**
 * @component
 * Component for rendering a download button with various states
 * @param {Object} props - The component props
 * @param {Object} props.file - The file object
 * @param {string} props.repositoryId - The ID of the repository
 * @param {Function} props.downloader - Function to handle file download
 * @param {Function} props.onPause - Function to handle download pause
 * @param {Function} props.onCancel - Function to handle download cancellation
 * @param {Function} props.onResume - Function to handle download resume
 * @param {Object} props.client - The client object
 * @param {Object} props.downloadPath - Object containing download paths for repositories
 * @returns {JSX.Element} The rendered DownloadButton component
 */

export const DownloadButton = ({
   file,
   repositoryId,
   downloader,
   onPause,
   onCancel,
   onResume,
   client,
   downloadPath,
}) => {
   const getFirstButtonAttributes = (file, downloader) => {
      const status = getDownloadStatus(file, repositoryId);
      switch (status) {
         case 'inprogress':
            return { icon: 'cmdPause24', onClickEvent: () => onPause([file]) };
         case 'paused':
            return { icon: 'cmdPlay24', onClickEvent: () => onResume([file]) };
         default:
            return {
               icon: 'cmdDownload24',
               datacy: 'cmdDownload',
               onClickEvent: () =>
                  isEmbedded()
                     ? fileStore.actions.downloadFilesEmbedded(client, downloadPath[repositoryId], repositoryId)([file])
                     : downloader([file]),
            };
      }
   };

   const getSecondButtonAttributes = (file) => {
      const status = getDownloadStatus(file, repositoryId);
      switch (status) {
         case 'inprogress':
         case 'paused':
            return { icon: 'cmdCancel24', onClickEvent: () => onCancel([file]) };
         case 'finished':
            // if the user downloads one file, changes then the download path and downloads another file,
            // this action will open the latest path also for the first file (which is wrong!).
            // But we had the same behaviour for the old UI
            // NOTE rework this to fix above "bug" - e.g. store directory for each downloaded file
            return {
               icon: 'cmdOpen24',
               onClickEvent: () =>
                  sendEmbeddedMessage('fileTransfer/openDirectory', {
                     downloadPath: downloadPath[repositoryId],
                  }),
            };
         default:
            return null;
      }
   };

   return (
      <div className="flex flex-wrap max-w-24">
         <SwwcButton variant="chromeless" size="xs" {...getFirstButtonAttributes(file, downloader)} />
         {getSecondButtonAttributes(file) && (
            <>
               <SwwcButton variant="chromeless" size="xs" {...getSecondButtonAttributes(file)} />
               <SwwcLoadingIndicator
                  className="w-20"
                  type="linear"
                  progress={getDownloadProgress(file, repositoryId)}
                  size="xxsmall"
               />
            </>
         )}
      </div>
   );
};
