import { pathEq, unless } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwwcToast } from '@/swwc';

const closeToastAfterDelay = (delay, toastRef) =>
   setTimeout(() => {
      toastRef.current.close();
   }, delay);

export const Toast = ({ toast, variant, clearToast }) => {
   const { t } = useTranslation();
   const [timerStartedAt, setTimerStartedAt] = useState(Date.now());
   const toastRef = useRef(null);
   const timerId = useRef(null);
   let delay = 6000;

   useEffect(() => {
      toastRef.current.open();
      timerId.current = closeToastAfterDelay(delay, toastRef);

      return () => {
         clearTimeout(timerId.current);
         timerId.current = null;
      };
   }, []);

   const handleMouseEnter = () => {
      clearTimeout(timerId.current);
      timerId.current = null;
      delay = delay - (Date.now() - timerStartedAt);
   };

   const handleMouseLeave = () => {
      timerId.current = closeToastAfterDelay(delay, toastRef);
      setTimerStartedAt(Date.now());
   };

   const isOpened = pathEq(true, ['detail', 'opened']);
   const handleChange = unless(isOpened, clearToast);

   return (
      <SwwcToast
         ref={toastRef}
         description={toast.description}
         toastTitle={t(`errors.${toast.type}`)}
         noAutoClose={true}
         variant={variant}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         onOpenedChanged={handleChange}
         className="top-16 right-2 z-20"
      />
   );
};
