import { useTranslation } from 'react-i18next';

import { store as settingsStore } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { getOssUrl } from '@/utils/formatters';

import { SwwcLink, SwwcPropertyValue, SwwcSwitch } from '@/swwc';

import { Section } from './telemetry/Section';
import { Settings as TelemetrySettings } from './telemetry/Settings';

const getYear = () => {
   const date = new Date();
   return date.getFullYear();
};

const handleChange = (e) => settingsStore.actions.setFullWidthTableSetting(localStorage, e.detail.checked);

export default function Settings() {
   const { fullWidthTable } = useStream(settingsStore.state);
   const { t } = useTranslation();
   return (
      <div className="max-w-screen-lg m-auto">
         <TelemetrySettings />
         <Section label={t('labels.about')}>
            <p className="mb-4">© Copyright {getYear()} Siemens Digital Industries Software</p>
            <span className="flex justify-between items-baseline">
               <SwwcLink
                  url={getOssUrl()}
                  text={t('common.thirdPartySoftware')}
                  target="_blank"
                  rel="noopener noreferrer"
               />
               <SwwcPropertyValue label={t('labels.buildNum')} colon>
                  <span slot="content">{import.meta.env.VITE_REACT_APP_VERSION}</span>
               </SwwcPropertyValue>
            </span>
         </Section>
         <Section label={t('labels.uiSettings')}>
            <SwwcSwitch checked={fullWidthTable} label={t('common.fullWidthTable')} onValueChange={handleChange} />
         </Section>
      </div>
   );
}
