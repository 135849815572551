import { createApp } from '@/store/common/pal';

import { AUTH_STORAGE_KEY } from '@/utils/constants';

import { actions } from './actions';
import { model } from './model';

function nap({ proposedState, context }) {
   context?.ls?.setItem(AUTH_STORAGE_KEY, JSON.stringify(proposedState));
   return proposedState;
}

export const authStore = createApp(actions, model, nap);
