import { useTranslation } from 'react-i18next';

import { SwwcRadioButton, SwwcRadioGroup } from '@/swwc';

const makeValue = (prefix, agreed) => {
   if (agreed === null) {
      return '';
   }

   return `${prefix}${agreed ? 'Agree' : 'Decline'}`;
};

export function Radio({ prefix, onAgreed, onDecline, agreed }) {
   const { t } = useTranslation();

   return (
      <div className="mt-4">
         <SwwcRadioGroup value={makeValue(prefix, agreed)}>
            <SwwcRadioButton
               label={t('labels.agree')}
               name={`${prefix}Radio`}
               value={`${prefix}Agree`}
               /**
                * If it's first user login (viwedTelemetry is false)
                * radios should be unselected
                * else, the value is based on user choice
                *  */
               checked={agreed === null ? false : agreed}
               onCheckedChange={onAgreed}
            ></SwwcRadioButton>
            <SwwcRadioButton
               label={t('labels.decline')}
               name={`${prefix}Radio`}
               value={`${prefix}Decline`}
               checked={agreed === null ? false : !agreed}
               onCheckedChange={onDecline}
            ></SwwcRadioButton>
         </SwwcRadioGroup>
      </div>
   );
}
