import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { jobTypesStore } from '@/store/jobtypes/store';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import styles from '@/modules/common/table.module.css';
import { Pagination } from '@/modules/listing/jobManager/components/pagination/Pagination';
import { Summary } from '@/modules/listing/jobManager/components/summary/Summary';

import { headers, PAGINATION_THRESHOLD } from '@/utils/constants';

import { TBody } from './TableBody';
import { createColumns } from './TableColumns';
import { THead } from './TableHeaders';

export const JobTable = () => {
   const { t } = useTranslation();
   const { mappings } = useStream(jobTypesStore.state);
   const { displayedJobs, selection } = useStream(store.state);

   const columns = useMemo(() => createColumns(t, mappings), [t, mappings]);

   const table = useReactTable({
      data: displayedJobs,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
         sorting: [{ id: headers.submitted.label, desc: true }],
         pagination: { pageIndex: 0, pageSize: PAGINATION_THRESHOLD },
      },
      autoResetPageIndex: false,
   });

   /**
    * table page index is 0 based compared to page number display, hence the +1/-1
    */
   const page = table.getState().pagination.pageIndex + 1;

   return (
      <>
         <div className="w-full lg:flex lg:justify-center overflow-x-auto sm:overflow-visible">
            <table className={styles.table}>
               <THead nSelectedJobs={selection.length} table={table} headers={headers} />
               <TBody selectedJobs={selection} table={table} />
            </table>
         </div>
         {displayedJobs.length > PAGINATION_THRESHOLD ? (
            <div className="flex justify-between items-center">
               <Summary page={page} totalEntries={displayedJobs.length} entriesPerPage={PAGINATION_THRESHOLD} />
               <Pagination table={table} />
            </div>
         ) : null}
      </>
   );
};
