import { compose, toLower } from 'ramda';
import { useTranslation } from 'react-i18next';

import { StatusIcon } from '@/modules/common/StatusIcon';

import { getDisplayedCost } from '@/utils/accessors';
import { isFailed, isFinished, isInsufficientCredits, isPendingOrQueued, isTimedOut } from '@/utils/comparators';
import { computeJobStatus, computeTaskUnifiedStatus } from '@/utils/constants';

const MessageAndCost = ({ message, status, subMessage }) => {
   return (
      <div className="flex flex-col items-center gap-4">
         <p className="flex gap-4 text-lg whitespace-pre text-center">
            {status ? <StatusIcon status={status} /> : null} {message}
         </p>
         {subMessage ? <p>{subMessage}</p> : null}
      </div>
   );
};

export const RemoteMessage = ({ session }) => {
   const { t } = useTranslation();
   const cost = getDisplayedCost(session);
   const statusToLower = compose(toLower, t);
   const remoteMessageCompletionStatus = 'remote.message.completionStatus';

   if (isInsufficientCredits(session)) {
      return (
         <MessageAndCost
            message={t('remote.message.submissionInsufficientCredits')}
            status={computeTaskUnifiedStatus.InsufficientCredits}
         />
      );
   }

   if (isPendingOrQueued(session)) {
      return <MessageAndCost message={t('remote.message.preparing')} />;
   }

   if (isTimedOut(session)) {
      return (
         <MessageAndCost
            message={t(remoteMessageCompletionStatus, {
               status: statusToLower(`status.${computeJobStatus.TimedOut}`),
            })}
            status={computeTaskUnifiedStatus.Failed}
            subMessage={t('remote.message.cost', { cost })}
         />
      );
   }

   if (isFailed(session)) {
      return (
         <MessageAndCost
            message={t(remoteMessageCompletionStatus, {
               status: statusToLower(`status.${computeJobStatus.Failed}`),
            })}
            status={computeTaskUnifiedStatus.Failed}
         />
      );
   }

   if (isFinished(session)) {
      return (
         <MessageAndCost
            message={t(remoteMessageCompletionStatus, {
               status: statusToLower(`status.${computeTaskUnifiedStatus.Terminated}`),
            })}
            status={computeTaskUnifiedStatus.Complete}
            subMessage={t('remote.message.cost', { cost })}
         />
      );
   }

   return null;
};
