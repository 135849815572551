import React from 'react';

import { TableRow } from './TableRow';

/**
 * @component
 * TableBody component for rendering the body of the file table
 * @param {Object} props - Component props
 * @param {Object} props.table - Table instance from useReactTable hook
 * @param {Function} props.onSelect - Function to handle file selection
 * @param {Array} props.selected - Array of currently selected files
 * @param {Function} props.onCancel - Function to handle cancellation of file operations
 * @param {Function} props.onPause - Function to handle pausing of file operations
 * @param {Function} props.onResume - Function to handle resuming of file operations
 * @param {Object} props.client - URQL client instance
 * @param {string} props.repositoryId - ID of the current repository
 * @param {Object} props.downloadPath - Object containing download paths
 * @returns {React.ReactElement} The body section of the file table
 */
export function TableBody({
   table,
   onSelect,
   selected,
   onCancel,
   onPause,
   onResume,
   client,
   repositoryId,
   downloadPath,
}) {
   return (
      <tbody>
         {table.getRowModel().rows.map((row) => (
            <TableRow
               key={row.id}
               row={row}
               onPause={onPause}
               onResume={onResume}
               onCancel={onCancel}
               onSelect={onSelect}
               selected={selected}
               repositoryId={repositoryId}
               client={client}
               downloadPath={downloadPath}
            />
         ))}
      </tbody>
   );
}
