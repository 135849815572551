import { stream } from 'flyd';
import { compose, equals, filter, mergeRight, not } from 'ramda';

import { getStatus } from '@/utils/accessors';
import { computeTaskUnifiedStatus, PAGINATION_THRESHOLD } from '@/utils/constants';

export const isNotDeleted = compose(not, equals(computeTaskUnifiedStatus.Deleted), getStatus);

export const runningJobs = stream(0);

/**
 * Updates the displayedJobs, pagination and filterOptions when any upstream modifier is updated
 * @param {*} proposedState
 */
export const transformState = (proposedState) => {
   const existingJobs = filter(isNotDeleted, proposedState.workspace.jobs);
   const existingLength = existingJobs.length;
   const totalPages = Math.max(1, Math.ceil(existingJobs.length / PAGINATION_THRESHOLD));
   const currentPage = Math.min(proposedState.page, totalPages);
   const totalDisplayedJobs = existingJobs.length;

   // const paginatedJobs = existingJobs.slice(
   //    PAGINATION_THRESHOLD * (currentPage - 1),
   //    PAGINATION_THRESHOLD * currentPage,
   // );

   return mergeRight(proposedState, {
      displayedJobs: existingJobs,
      page: currentPage,
      totalDisplayedJobs,
      totalPages,
      totalExistingJobs: existingLength,
      runningJobs: runningJobs(),
   });
};
