import { useTranslation } from 'react-i18next';

import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { SwwcInformationBar } from '@/swwc';

const warnLabel = 'warning.label';
export const WarnSessionIsInProcess = ({ className }) => {
   const { t } = useTranslation();
   const {
      warnUserRemoteSession: { runningSessions, userWantsToBeWarned },
   } = useStream(store.state);

   if (!runningSessions || !userWantsToBeWarned) {
      return null;
   }

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('warning.executingRDWarning')}
            variant="warning"
            useIcon
            closable
            label={t(warnLabel)}
            onCloseEvent={() => store.actions.setWarnUserInfo(false)}
         />
      </div>
   );
};

export const WarnHPCPreparing = ({ className }) => {
   const { t } = useTranslation();
   const { warnUserHPCJob } = useStream(store.state);

   if (!warnUserHPCJob) {
      return null;
   }

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('warning.submissionInProgress')}
            variant="warning"
            useIcon
            label={t(warnLabel)}
         />
      </div>
   );
};

export const WarnInvalidStatus = ({ className }) => {
   const { t } = useTranslation();

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('errors.invalidStatus')}
            variant="warning"
            useIcon
            label={t(warnLabel)}
         />
      </div>
   );
};
