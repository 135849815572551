import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { store } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { resetRefinerUser, setRefinerUser } from '@/analytics/refiner';

import { Radio } from './Radio';
import { Section } from './Section';

const link = 'https://www.sw.siemens.com/en-US/trust-center/digital-product-experience/';

export function DigitalProductExperience() {
   const { t } = useTranslation();
   const client = useClient();
   const { userEntitlementData, telemetry } = useStream(store.state);

   const onAgreed = () => {
      store.actions.updateDigitalProductExperience(client, userEntitlementData.id, true);
      setRefinerUser();
   };

   const onDecline = () => {
      resetRefinerUser();
      store.actions.updateDigitalProductExperience(client, userEntitlementData.id, false);
   };

   return (
      <Section label={t('telemetry.dpe.label')}>
         <p>{t('telemetry.dpe.info')}</p>
         <br />
         <p className="relative">
            {t('telemetry.dpe.trustCenter')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={link} className="font-bold text-status-blue">
               {t('telemetry.trustCenterLink')}
            </a>
         </p>
         <br />
         <p>{t('telemetry.dpe.consent')}</p>
         <Radio prefix="dpe" onAgreed={onAgreed} onDecline={onDecline} agreed={telemetry.telemetryEnabled} />
      </Section>
   );
}
