import { useTranslation } from 'react-i18next';

import { actionsModalStore } from '@/store/modal/store';

import { AppsButtons } from '@/modules/common/AppsButtons';

import { SwwcButton } from '@/swwc';

export const CommandBar = ({ selection }) => {
   const { t } = useTranslation();
   const hasNoSelection = selection.length === 0;

   return (
      <div className="flex items-center gap-2 flex-wrap">
         <AppsButtons />
         <SwwcButton
            type="button"
            label={t('commands.delete')}
            icon="cmdDelete16"
            onClick={() => actionsModalStore.actions.open(selection, 'delete')}
            disabled={hasNoSelection}
            data-cy="delete"
         />
      </div>
   );
};
