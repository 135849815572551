/**
 * Store containing the user settings/preferences
 */
export const model = {
   fullWidthTable: true,
   userEntitlementData: {
      dataState: 'ready',
      id: '',
   },
   telemetry: {
      pepTelemetryEnabled: false,
      telemetryEnabled: true,
      viewedTelemetry: false,
   },
   granted: false,
   wmcUrl: import.meta.env.VITE_WMC_URL,
};
