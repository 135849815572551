import { chain, curry, path, unless } from 'ramda';

const getStartLine = path(['scrollData', 'startLine']);
const startLineDidNotChange = curry((stream, newScrollData) => {
   const newStart = getStartLine(newScrollData);
   const oldStart = getStartLine(stream());

   return newStart === oldStart || (newStart === 0 && oldStart === undefined);
});

export const updateScrollDataWhenChanged = chain(unless, startLineDidNotChange);
