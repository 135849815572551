import { gql } from '@urql/core';

export default gql`
   query UserEntitlementData {
      userEntitlementData {
         id
         csaAcceptanceDate
         viewedTelemetry
         telemetryEnabled
         pepTelemetryEnabled
      }
   }
`;
