import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '@/locales/en/translation.json';

i18n.use(initReactI18next).init({
   fallbackLng: 'en',
   lng: 'en',
   resources: {
      en: {
         translation: translationEN,
      },
   },
   interpolation: {
      escapeValue: false,
   },
});
