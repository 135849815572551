import hpcJobIcon from '/img/hpcJob.svg';
import remoteDesktopIcon from '/img/remoteDesktopJob.svg';

/**
 * Maps job types to their corresponding icon file paths.
 * @constant
 * @type {Object.<string, string>}
 * @property {string} remote_desktop - Path to the remote desktop job icon.
 * @property {string} cloud_hpc - Path to the HPC job icon.
 * @example
 * const jobType = "remote_desktop";
 * const iconPath = iconMap[jobType];
 */

/* eslint-disable camelcase */
export const iconMap = {
   remote_desktop: remoteDesktopIcon,
   cloud_hpc: hpcJobIcon,
};
