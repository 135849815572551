import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { fileStore } from '@/store/files/store';
import { useStream } from '@/store/useStream';

import styles from '@/modules/common/table.module.css';
import { Pagination } from '@/modules/listing/jobManager/components/pagination/Pagination';
import { Summary } from '@/modules/listing/jobManager/components/summary/Summary';

import { FILES_PAGINATION_THRESHOLD, fileTableHeaders } from '@/utils/constants';

import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { fileTableColumns } from './fileTableColumns';

/**
 * @component
 * Renders a table displaying file information with sorting and selection capabilities
 * @param {Object} props - The component props
 * @param {Array} props.data - Array of file objects to display in the table
 * @param {Function} props.onSelect - Function to handle file selection
 * @param {Array} props.selected - Array of currently selected files
 * @param {Function} props.onCancel - Function to handle download cancellation
 * @param {Function} props.onPause - Function to handle download pause
 * @param {Function} props.onResume - Function to handle download resume
 * @returns {JSX.Element} The rendered FileTable component
 */

export function FileTable({ data, onSelect, selected, onCancel, onPause, onResume }) {
   const client = useClient();
   const { t } = useTranslation();
   const { fileRepository, downloadPath } = useStream(fileStore.state);
   const repositoryId = fileRepository?.input?.id;

   const columns = useMemo(() => fileTableColumns(t), [t]);

   const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
         sorting: [
            {
               id: fileTableHeaders.type.label,
               desc: true,
            },
         ],
         pagination: {
            pageIndex: 0,
            pageSize: FILES_PAGINATION_THRESHOLD,
         },
      },
      autoResetPageIndex: false,
   });

   /**
    * table page index is 0 based compared to page number display, hence the +1/-1
    */
   const page = table.getState().pagination.pageIndex + 1;

   return (
      <>
         <table
            className={`grow relative shrink basis-auto overflow-auto h-0 flex flex-col min-h-64 ${styles.table} ${styles.fileTable}`}
         >
            <TableHeader table={table} />
            <TableBody
               table={table}
               onSelect={onSelect}
               selected={selected}
               onCancel={onCancel}
               onPause={onPause}
               onResume={onResume}
               client={client}
               repositoryId={repositoryId}
               downloadPath={downloadPath}
            />
         </table>
         {data.length > FILES_PAGINATION_THRESHOLD ? (
            <div className="flex justify-between items-center pt-4">
               <Summary page={page} totalEntries={data.length} entriesPerPage={FILES_PAGINATION_THRESHOLD} />
               <Pagination table={table} />
            </div>
         ) : null}
      </>
   );
}
