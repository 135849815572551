export default [
   /**
    * Example of defining a remote
    */
   // {
   //    name: 'systems_catalog',
   //    entry: 'http://localhost:5001/remoteEntry.js',
   //    alias: 'systems',
   //    type: 'module',
   // },
];
