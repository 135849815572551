import { dataStatePath, dataStates } from '@/utils/constants';

import services from './services';
import { initialFeatureFlags } from './state';

export const rawActions = (present) => ({
   loadFeatureFlags: (client) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getFeatureFlags(client)
         .then((data) => {
            present([
               { op: 'replace', value: data.userFeatureFlags, path: '/userFeatureFlags' },
               { op: 'replace', value: dataStates.ready, path: dataStatePath },
            ]);
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath });
         });
   },

   clear: () => present({ op: 'replace', value: initialFeatureFlags, path: '' }),
});
