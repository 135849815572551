import { gql } from '@urql/core';

export default gql`
   query GetSystemJobTemplates {
      workspace {
         systemJobTemplateCatalog {
            systemJobTemplates {
               jobType
               applicationsSupported {
                  type
                  version
                  precision
                  typeHint
               }
               computeTypes {
                  name
                  usableCoreCount
                  hourlyCreditRate
                  typeHint
               }
            }
            displayMappings {
               key
               value
            }
         }
      }
   }
`;
