/*
 * Supported fetch policies setting
 * https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
 */
export const fetchPolicies = {
   cacheFirst: 'cache-first',
   cacheOnly: 'cache-only',
   cacheAndNetwork: 'cache-and-network',
   networkOnly: 'network-only',
   noCache: 'no-cache',
   standby: 'standby',
};
