import { useTranslation } from 'react-i18next';

import { buildJobActionsClickHandler } from '@/utils/buildJobActionsClickHandler';
import { getActions } from '@/utils/jobUtils';

import { SwwcButton } from '@/swwc';

export const QuickActions = ({ job }) => {
   const onClick = buildJobActionsClickHandler(job);
   const { t } = useTranslation();

   return (
      <>
         {getActions(job).map(({ icon, id }) => (
            <SwwcButton
               key={`action-${id}`}
               variant="chromeless"
               icon={icon}
               size="xs"
               onClickEvent={() => onClick(id)}
               title={t(`commands.${id}`)}
               iconPosition="center"
               hiddenLabel
            />
         ))}
      </>
   );
};
