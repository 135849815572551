import { prop } from 'ramda';

import { GetFeatureFlags } from '@/gql';
import { fetchPolicies } from '@/gql/constant';

import { throwWhenError } from '@/store/common/utils';

const getFeatureFlags = (client) =>
   client
      .query(GetFeatureFlags, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError('featureFlags'))
      .then(prop('data'));

export default {
   getFeatureFlags,
};
