import { always, clamp, compose, flip, gt, ifElse, inc, lt, range } from 'ramda';

const GAP = 2;
const CLAMP_MIN = 6;
export const isAboveStartGap = lt(1 + GAP);

export const getPages = (page, totalPages) => {
   if (totalPages <= 10) {
      return range(1, totalPages + 1);
   }

   /**
    * Threshold to determine whether we display ellipsis or pages between pagination edge
    * and current page siblings edges
    */
   const isBelowEndGap = gt(totalPages - GAP);

   /**
    * Total number of buttons between navigations buttons should always remain the same (9)
    * To ensure that we clamp the siblings edges values to make sure that the siblings window
    * is 5 in the middle of the pagination and extends on one side when at the edge of the pagination
    */
   const lowerSiblingEdge = clamp(2, totalPages - CLAMP_MIN, page - GAP);
   const upperSiblingEdge = clamp(1 + CLAMP_MIN, totalPages - 1, page + GAP);

   const getEndRange = compose(flip(range)(totalPages + 1), inc);

   /**
    * Splits the pages in three sections :
    * - The window based on clamped siblings edges and current page inside
    * - Pages from 1 to lower sibling, with either ellipsis or range of pages
    * - Pages from upper sibling to total pages, with either ellipsis or range of pages
    */
   const siblingsPages = range(lowerSiblingEdge, upperSiblingEdge + 1);
   const getStartPages = ifElse(isAboveStartGap, always([1, '…']), range(1));
   const getEndPages = ifElse(isBelowEndGap, always(['…', totalPages]), getEndRange);

   return [...getStartPages(lowerSiblingEdge), ...siblingsPages, ...getEndPages(upperSiblingEdge)];
};
