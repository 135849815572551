import { flip, includes, tap } from 'ramda';

import { oneSecondInMs } from './constants';

export const delay = (delayInms) =>
   new Promise((resolve) => {
      setTimeout(resolve, delayInms);
   });

export const within = flip(includes);

export const duplicate = (val) => [val, val];

export function debounce(callback, wait = 500) {
   let timeoutId = null;
   return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
         callback(...args);
      }, wait);
   };
}

export const getTimeSpentInSeconds = (start, end) => Math.floor((end - start) / oneSecondInMs);

/**
 * @public
 */
export const log = (label) => tap((x) => console.log(label, x));
