import { gql } from '@urql/core';

export default gql`
   query GetWorkspace {
      userFeatureFlags {
         name
         value
      }
   }
`;
