/* eslint max-lines: 0 */
// @ts-nocheck
import { always, filter, isNil, mergeDeepLeft, path, propEq, propSatisfies, tap, when } from 'ramda';

import { handleSubmitError } from '@/store/common/utils';
import { uploadFiles } from '@/store/files/files';
import { fileStore } from '@/store/files/store';
import { store } from '@/store/listing/state';
import services from '@/store/services';

import { findByResourceId } from '@/utils/accessors';
import { isFinished, isNotNilOrEmpty } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStatePath, dataStates, jobTypeKeys } from '@/utils/constants';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';

import { trackSubmitNewHPCJob } from '@/analytics/events';
import { refinerTrackHPCJobSubmission } from '@/analytics/refiner';

import { model } from './model';

const isTerminated = (resourceId) => {
   const job = findByResourceId(resourceId)(store.state().workspace.jobs);

   return isFinished(job);
};
const isTerminatedorUndefined = (key, item) => isNil(item) || isTerminated(item[key]);
const merge = (updatedJob) => when(propEq(updatedJob.resourceId, 'resourceId'), mergeDeepLeft(updatedJob));

const updateJobToQueued = tap((job) => {
   job.tasks[0].unifiedStatus = computeTaskUnifiedStatus.Queued;
   store.actions.updateJob(merge(job));
});

export const createJob = (client, form, jobTypes, token, secondsSpent) => {
   return services.createJobAndInput(client, form, jobTypes, token).then((job) => {
      trackSubmitNewHPCJob(window.San, { form, secondsSpent, jobId: job.resourceId });
      refinerTrackHPCJobSubmission();
      job.tasks[0].unifiedStatus = computeTaskUnifiedStatus.Creating;
      store.actions.addJob(job);
      return job;
   });
};

export const submitJob = (client, present) => (job) => {
   if (!isTerminated(job.resourceId)) {
      job.tasks[0].unifiedStatus = computeTaskUnifiedStatus.Submitting;
      store.actions.updateJob(merge(job));

      return services
         .submitJob(client, job.resourceId)
         .then(updateJobToQueued)
         .catch(handleSubmitError(present)(job.resourceId, jobTypeKeys.hpc));
   }
};

export const prepareAndUploadFiles = (client, form) => (job) => {
   if (!isTerminatedorUndefined('resourceId', job)) {
      job.tasks[0].unifiedStatus = computeTaskUnifiedStatus.Uploading;
      const task = job.tasks[0];
      const fileList = filter(propSatisfies(isNotNilOrEmpty, 'name'), [
         form.simulationFile,
         form.macro,
         ...form.otherFiles,
      ]);

      store.actions.updateJob(merge(job));
      fileStore.actions.setJobFiles({ taskId: task.id, form });

      return uploadFiles(client, task.inputFileRepository.id, fileList, task.id, form)
         .catch(() => {
            store.actions.terminateJob(client, job);
            throw new Error(`Failed to upload files for job ${job.resourceId}`, { cause: job });
         })
         .then(always(job));
   }
   return Promise.resolve(job);
};

export const palActions = (present) => ({
   loadJobInfo: (client, jobResourceId) => {
      present({ dataState: dataStates.loading });
      return services
         .getJobDetails(client, jobResourceId)
         .then((productInformation) => {
            present({
               productInformation: {
                  computeTypeName: path(['jobDefinition', 'computeType', 'name'], productInformation),
                  task: path(['tasks', '0'], productInformation),
               },
               dataState: dataStates.ready,
            });
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath });
         });
   },

   clear: () => present({ op: 'replace', value: model, path: '' }),

   clearError: () => present({ op: 'replace', value: null, path: '/error' }),

   runJobWorkflow: (client, form, applicationTemplates, seconds) => {
      sendEmbeddedMessage('submittingJob', { inProgress: true });
      const idempotencyToken = crypto.randomUUID();

      // Step #1: Job + Task creation
      const promise = createJob(client, form, applicationTemplates, idempotencyToken, seconds);

      promise
         .then(prepareAndUploadFiles(client, form))
         .then(submitJob(client, present))
         .then(() => sendEmbeddedMessage('submittingJob', { inProgress: false }))
         .catch((e) => {
            console.error(e);
         });

      return promise;
   },
});
