import { createApp } from '@/store/common/pal';

import { dataStates } from '@/utils/constants';

import { rawActions } from './actions';
import { nap } from './nap';

export const initialCredits = { credits: null, dataState: dataStates.loading, hasPositiveEffectiveBalance: false };

export const creditStore = createApp(rawActions, initialCredits, nap);
