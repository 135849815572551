import { gql } from '@urql/core';

export default gql`
   query ComputeTaskLog($computeTaskId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
      node(id: $computeTaskId) {
         id
         ... on ComputeTask {
            outputLines(input: { after: $after, first: $first, before: $before, last: $last }) {
               nodes
               pageInfo {
                  hasNextPage
                  startCursor
                  endCursor
               }
            }
         }
      }
   }
`;
