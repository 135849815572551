import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { creditStore } from '@/store/credits/state';
import { useStream } from '@/store/useStream';

import { dataStates } from '@/utils/constants';

import { LoadingIndicator } from './LoadingIndicator';

export const CreditsHandler = ({ hourlyCreditRate, type }) => {
   const { t } = useTranslation();
   const { credits, dataState, hasPositiveEffectiveBalance } = useStream(creditStore.state);

   if (dataState === dataStates.loading && isNil(credits)) {
      return <LoadingIndicator size="xxsmall" />;
   }

   if (dataState === dataStates.error) {
      return <p className="text-red-500">{t('credits.error')}</p>;
   }

   return hasPositiveEffectiveBalance ? (
      <p className="text-lg font-medium text-black-800">
         {t('credits.consumptionEstimate', {
            value: hourlyCreditRate,
         })}
      </p>
   ) : (
      <p className="text-red-500">{t('credits.lackingAmount', { type })}</p>
   );
};
