/* eslint-disable camelcase */
// @ts-nocheck
import { compose, curry, equals, ifElse, isNotNil, mergeLeft, path, tap, when } from 'ramda';

import { getJsonWithStatus } from '@/utils/accessors';
import { AUTH_STORAGE_KEY, invalidTokenError } from '@/utils/constants';
import { removeTrailingSlash } from '@/utils/formatters';

import { getFullDomain } from './domains';
import { generateChallenge, generateCodeVerifier, storeVerifier } from './pkce';
import { authStore } from './store';
import {
   appServerFromStateParam,
   codeIsNotNil,
   decodeToken,
   getExpiryMs,
   getOriginAndQuery,
   qsFromObject,
   REFRESH_DIFF,
   scheduleRefresh,
   verifierAsObj,
} from './utils';

const makeAppServerEndpoint = (endpoint) => `${import.meta.env.VITE_APP_SERVER_PREFIX}${endpoint}`;

/**
 * If an appserver is provided as query param, it will be present as such in the url on the first access.
 * Then after navigating to the auth and being redirected back, it won't be anymore and needs to be accessible
 * via the state search param.
 * If at this point the appserver information is in neither param, we use the default appserver based on the region.
 */
const getAppServerFromParams = (params) => {
   const appserver = params.get('appserver');
   const state = params.get('state');

   if (isNotNil(appserver)) {
      return appserver;
   }

   return appServerFromStateParam(state);
};

const withAppServerQuery = (endpoint, search) => {
   const params = new URLSearchParams(search);
   const appserver = getAppServerFromParams(params);

   if (appserver) {
      return `${endpoint}?appserver=${appserver}`;
   }
   return endpoint;
};

const authClient = {
   authEndpoint: import.meta.env.VITE_AUTH_ENDPOINT,
   redirectUri: import.meta.env.VITE_FIXED_REDIRECT_URI,
   clientId: import.meta.env.VITE_CLIENT_ID,
};

const getRegion = path(['accessTokenData', 'sws.samauth.tiers', 0, 'region']);

export const prepareTokensWithCode = ({ code, verifier, ...passThrough }) => ({
   grant_type: 'authorization_code',
   client_id: authClient.clientId,
   code_verifier: verifier,
   code,
   redirect_uri: authClient.redirectUri,
   tokenEndpoint: withAppServerQuery(`${authClient.authEndpoint}/token`, window.location.search),
   passThrough,
});

export const verifyTokenPermission = (token) => {
   if (!token.accessTokenData.permissions.includes('use:cloud-connected')) {
      throw new Error(invalidTokenError);
   }

   return token;
};

export const makeAppServerUrl = curry((params, data) => {
   const appserver = appServerFromStateParam(params.get('state'));

   const niceDCVUrl = getFullDomain(
      getRegion(data),
      import.meta.env.VITE_NICEDCV_GATEWAY_DOMAIN,
      import.meta.env.VITE_APP_SERVER_PREFIX,
   );

   if (appserver) {
      return mergeLeft({ appServerUrl: makeAppServerEndpoint(appserver), niceDCVUrl }, data);
   }

   const appServerUrl = getFullDomain(
      getRegion(data),
      import.meta.env.VITE_APP_SERVER_DOMAIN,
      import.meta.env.VITE_APP_SERVER_PREFIX,
   );

   return mergeLeft({ appServerUrl, niceDCVUrl }, data);
});

export const prepareRefresh = compose(
   mergeLeft({ grant_type: 'refresh_token', client_id: authClient.clientId }),
   ({ refresh_token, appServerUrl }) => ({ refresh_token, tokenEndpoint: `${appServerUrl}/token` }),
   authStore.state,
);

export const refreshTokens = compose(fetchTokensForRefresh, prepareRefresh);

function redirectToUnauthorized(err) {
   localStorage.removeItem(AUTH_STORAGE_KEY);
   window.location.href = `${removeTrailingSlash(import.meta.env.BASE_URL)}/unauthorized?message=${err?.message}&code=${err?.extensions?.code}`;
}

function fetchTokensForRefresh(data) {
   return fetchTokens(data)
      .then(tap(authStore.actions.setAuthData(localStorage)))
      .then(scheduleRefresh(refreshTokens))
      .catch(redirectToUnauthorized);
}

function fetchInitialTokens({ passThrough, ...data }) {
   return fetchTokens(data)
      .then(makeAppServerUrl(new URLSearchParams(window.location.search)))
      .then(tap(compose(authStore.actions.setAuthData(localStorage), mergeLeft(passThrough))))
      .then(scheduleRefresh(refreshTokens))
      .catch(redirectToUnauthorized);
}
function fetchTokens({ tokenEndpoint, ...data }) {
   return fetch(tokenEndpoint, {
      method: 'POST',
      headers: tokenAuthHeaders(),
      body: qsFromObject(data),
   })
      .then(getJsonWithStatus)
      .then(
         when(
            ({ httpStatus }) => [400, 403].includes(httpStatus),
            (r) => Promise.reject(r),
         ),
      )
      .then(decodeToken)
      .then(verifyTokenPermission);
}

const fetchTokensWithCode = compose(fetchInitialTokens, prepareTokensWithCode);

/**
 * Checking if the token is valid.
 * @returns
 */
export function tokenIsInDate(authState = null) {
   const data = authState || authStore.state();
   const expiry = getExpiryMs(data);
   return expiry >= Date.now() - REFRESH_DIFF;
}

/**
 * Tries to retrieve existing auth data from local storage
 * and populate the stream
 */
export function initializeAuthData(ls, refreshFn) {
   const data = JSON.parse(ls.getItem(AUTH_STORAGE_KEY));
   if (tokenIsInDate(data)) {
      if (!data.appServerUrl) {
         console.log('Token is in data but appserverURL is not present');
         console.dir(data);
      }
      authStore.actions.setAuthData(ls, data);
      scheduleRefresh(refreshFn, data);
   } else {
      console.log('Token expired, remove auth data');
      ls.removeItem(AUTH_STORAGE_KEY);
   }
}

/**
 *
 * This function should be invoked in a protected route.
 * It will do the following:
 * - If the URL query parameters include 'code', assume we are within the authentication flow
 * and try to exchange the code for an access token.
 * - Check for the existence and validity of the access token.
 *
 */
export const tryAuth = (ss) =>
   compose(
      ifElse(codeIsNotNil, fetchTokensWithCode, Promise.reject.bind(Promise)),
      verifierAsObj(ss),
      getOriginAndQuery,
   );

export function tokenAuthHeaders() {
   return { 'Content-Type': 'application/x-www-form-urlencoded' };
}

const makeAuthParams = curry(({ search, origin, href }, challenge) => {
   const state = {
      originRedirectUri: `${origin}${removeTrailingSlash(import.meta.env.BASE_URL)}/callback`,
      requestedUrl: href.substring(origin.length),
   };

   const appserver = getAppServerFromParams(new URLSearchParams(search));
   if (appserver) {
      state.appserver = appserver;
   }

   const params = new URLSearchParams([
      ['client_id', authClient.clientId],
      ['scope', 'openid profile'],
      ['response_type', 'code'],
      ['redirect_uri', authClient.redirectUri],
      ['state', JSON.stringify(state)],
      ['code_challenge', challenge],
      ['code_challenge_method', 'S256'],
   ]);

   return params;
});

/**
 * Step 1 of authentication process - this prepares the authentication request.
 * It must be a redirect (AJAX/Fetch cannot be used)
 * See:
 * https://openid.net/specs/openid-connect-basic-1_0.html#CodeFlow
 * sections 2.1.1-2.1.2
 */
export function login() {
   const verifier = generateCodeVerifier(crypto);
   storeVerifier(window.sessionStorage, verifier);
   generateChallenge(crypto)(verifier)
      .then(makeAuthParams(window.location))
      .then((params) => {
         window.location.href = `${authClient.authEndpoint}/auth?${params.toString()}`;
      });
}

export function createLogoutUrl() {
   const { id_token, appServerUrl } = authStore.state();
   // End SAM session
   const params = new URLSearchParams([
      ['client_id', authClient.clientId],
      ['post_logout_redirect_uri', import.meta.env.VITE_POST_LOGOUT_URI],
   ]);

   if (id_token) {
      params.append('id_token_hint', id_token);
   }
   if (!appServerUrl) {
      return `${authClient.authEndpoint}/logout?${params.toString()}`;
   }
   // Whereas login is typically handled by lambda functions,
   // logout is handled by appserver -> the host for logout is not necessarily
   // the same as for login.
   return `${appServerUrl}/logout?${params.toString()}`;
}

const getErrorAppCode = path(['result', 'extensions', 'code']);
export const isInvalidSession = compose(equals('APP_AuthorizationError'), getErrorAppCode);
