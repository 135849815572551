import { useTranslation } from 'react-i18next';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';

import { isTaskPreparing } from '@/utils/comparators';

import { SwwcInformationBar } from '@/swwc';

import { LogNodes } from './LogNodes';

export const JobLogs = withErrorBoundary(({ task = {}, outputRepoIsReady }) => {
   const { t } = useTranslation();

   if (isTaskPreparing(task)) {
      return (
         <SwwcInformationBar
            closable={false}
            label=""
            buttonText=""
            fullWidthMode={false}
            informationText={t('logs.wait')}
            useIcon={true}
            variant="info"
         />
      );
   }

   return <LogNodes task={task} outputRepoIsReady={outputRepoIsReady} />;
});
