import { compose, path, replace } from 'ramda';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { tokenIsInDate, tryAuth } from '@/store/auth/auth';
import { authStore } from '@/store/auth/store';
import { useStream } from '@/store/useStream';

import { invalidTokenError } from '@/utils/constants';
import { addPrecedingSlash } from '@/utils/formatters';

const getRedirectPath = compose(
   addPrecedingSlash,
   replace(import.meta.env.BASE_URL, ''),
   path(['state', 'requestedUrl']),
);

/**
 * Step 2 of authentication flow
 * This component handles the code callback from the authorization code OAuth flow
 * (See https://openid.net/specs/openid-connect-basic-1_0.html#CodeResponse).
 *
 * The route at which it is mounted (e.g. /callback) should be submitted as the redirect_uri
 * to the initial `code` request. The auth server redirects to this URL
 * and the URL query params should contain `code`.
 * This should be exchanged in a fetch POST request for a token (Step 3).
 * After receiving a token, we can then navigate back to the requested page (Step 4)
 * @returns
 */
export const Callback = () => {
   const [err, setErr] = useState(null);
   const data = useStream(authStore.state);
   const navigate = useNavigate();

   useEffect(() => {
      setErr(null);
      tryAuth(window.sessionStorage)(window.location).catch(setErr);
   }, []);

   useEffect(() => {
      if (tokenIsInDate(data)) {
         navigate(getRedirectPath(data));
      }
   }, [data]);

   if (err) {
      if (err.message === invalidTokenError) {
         return <Navigate to="/unauthorized" replace />;
      }

      return <p>There was an error: {err.message}</p>;
   }
   return null;
};
