// @ts-nocheck
import { isNil, isNotNil, path } from 'ramda';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { fileStore } from '@/store/files/store';
import { jobDetailsStore } from '@/store/hpcJob/store';
import services from '@/store/services';
import { useStream } from '@/store/useStream';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';
import { WarnInvalidStatus } from '@/modules/common/WarnUser';

import { stopFileRepositoryPolling } from '@/utils/JobOutputUtils';
import { getStatus, getTask } from '@/utils/accessors';
import { isAnyFiniteStatus } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStates } from '@/utils/constants';

import { SwwcTab, SwwcTabset } from '@/swwc';

import { JobFiles } from './files/JobFiles';
import { JobLogs } from './logs/JobLogs';

const tabs = ['Logs', 'Files'];

export const JobOutput = withErrorBoundary(({ job }) => {
   const client = useClient();
   const { productInformation } = useStream(jobDetailsStore.state);
   const { fileRepository } = useStream(fileStore.state);
   const [selectedTab, setSelectedTab] = useState();

   const { t } = useTranslation();
   const task = getTask(job);
   const taskStatus = getStatus(job);
   const outputFileRepositoryUrn = path(['task', 'outputFileRepository', 'urn'], productInformation);

   useEffect(() => {
      if (isNil(selectedTab) && isNotNil(taskStatus)) {
         setSelectedTab(isAnyFiniteStatus(job) ? 'Files' : 'Logs');
      }

      if (isAnyFiniteStatus(job) && isNotNil(productInformation)) {
         return fileStore.actions.loadJobOutputFiles(() =>
            services.frsOutputFileRepository(client, outputFileRepositoryUrn, stopFileRepositoryPolling(taskStatus)),
         );
      }
   }, [taskStatus, outputFileRepositoryUrn]);

   if (taskStatus === computeTaskUnifiedStatus.Pending) {
      return (
         <div className="col-start-2 col-end-5">
            <WarnInvalidStatus />
         </div>
      );
   }

   return (
      <div className="flex flex-col col-start-1 col-end-5 md:col-start-2 md:col-end-6">
         <SwwcTabset variant="base" borderless>
            {tabs.map((tab) => (
               <SwwcTab
                  key={tab}
                  label={t(`common.${tab}`)}
                  selected={tab === selectedTab}
                  onTabSelected={() => setSelectedTab(tab)}
                  className={tab === selectedTab ? 'selected' : ''}
               />
            ))}
         </SwwcTabset>
         {selectedTab === 'Logs' ? (
            <JobLogs task={task} outputRepoIsReady={fileRepository.output.status === 'READY'} />
         ) : (
            <JobFiles task={task} outputRepoIsReady={fileRepository.dataState.output === dataStates.ready} />
         )}
      </div>
   );
});
