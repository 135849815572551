import { gql } from '@urql/core';

export default gql`
   mutation UpdateUserEntitlementData($input: UpdateUserEntitlementDataInput!) {
      updateUserEntitlementData(input: $input) {
         entitlementDataId
         viewedTelemetry
         csaAcceptanceDate
         telemetryEnabled
      }
   }
`;
