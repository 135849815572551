import { model } from './model';

export const actions = (present) => {
   return {
      open: (jobs, action) => {
         present([
            { op: 'replace', value: jobs, path: '/selection' },
            { op: 'replace', value: action, path: '/action' },
         ]);
      },

      close: () => {
         present({ op: 'replace', value: model, path: '' });
      },
   };
};
