import {
   compose,
   cond,
   eqProps,
   equals,
   filter,
   find,
   includes,
   is,
   isNil,
   isNotNil,
   join,
   map,
   mergeDeepLeft,
   on,
   prop,
   T,
   test,
   toLower,
   unionWith,
} from 'ramda';

import { errorTypes, fileTypes, oneSecondInMs, retryNb } from '@/utils/constants';
import { getBaseName } from '@/utils/formatters';
import { delay } from '@/utils/utils';

const testExtension = (extension) => compose(test(extension), prop('filename'));
const addTypeAndRepositoryID = (id) => (file) => {
   const matchingFile = find(({ validator }) => testExtension(validator)(file), fileTypes);
   return mergeDeepLeft({ repositoryId: id, type: matchingFile?.type }, file);
};
const addRepositoryId = (repo) => {
   if (isNotNil(repo)) {
      return map(addTypeAndRepositoryID(repo.id), repo.files);
   }
   return [];
};

export const nameIncludes = (v) => compose(includes(v), toLower, prop('filename'));
export const filterFiles = compose(filter, nameIncludes);

export const MIN_PART_SIZE = 5 * 1024 * 1024;

// f(g(x))(g(y)) -- applies addRepositoryId to each of the 2 input arguments and then unions the
// two lists by filename
export const allFilesWithRepoId = on(unionWith(eqProps('filename')), addRepositoryId);

function hexToBase64(hexstring) {
   return btoa(
      hexstring
         .match(/\w{2}/g)
         .map((a) => String.fromCharCode(parseInt(a, 16)))
         .join(''),
   );
}

export const computeChecksum = (data) =>
   crypto.subtle
      .digest('SHA-256', data)
      .then((hashBuffer) => Array.from(new Uint8Array(hashBuffer)))
      .then(map((b) => b.toString(16).padStart(2, '0')))
      .then(join(''))
      .then(hexToBase64);

export function computeFilePartsData(file) {
   if (!is(File, file)) {
      throw new Error('Invalid file');
   }

   const fileName = getBaseName(file.name);
   const fileSize = file.size;
   const partSize = Math.max(MIN_PART_SIZE, Math.ceil(fileSize / 10000));
   const numParts = Math.ceil(fileSize / partSize);

   return {
      fileName,
      fileSize,
      filePartSize: partSize,
      fileNumParts: numParts,
   };
}

/**
 * retryWithBackoff
 * Try to execute fn function several times after a backoff delay before faiing
 */
export function retryWithBackoff(fn, retries = retryNb, delayInMs = oneSecondInMs) {
   return fn().catch((error) => {
      if (retries <= 0 || error.name === errorTypes.abortError) {
         return Promise.reject(error); // Reject after retries are exhausted
      }

      return delay(delayInMs).then(() => retryWithBackoff(fn, retries - 1, delayInMs * 2)); // Exponential backoff
   });
}

/**
 * Computes the loaded value of a file based on its progress.
 */
export const computeLoadedValue = (fileData, currentProgressCounter) =>
   cond([
      [() => isNil(currentProgressCounter), () => 0],
      [() => equals(fileData.fileNumParts, currentProgressCounter), () => fileData.fileSize],
      [T, () => currentProgressCounter * fileData.filePartSize],
   ])();
