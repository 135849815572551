import { isNil, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { creditStore } from '@/store/credits/state';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { getBalance } from '@/utils/accessors';
import { dataStates } from '@/utils/constants';

import { SwwcIcon, SwwcInformationBar, SwwcLink, SwwcPopupMenu, SwwcPropertyValue } from '@/swwc';

const getTotal = propOr('0', 'totalCost');

export const Credits = withErrorBoundary(() => {
   const { runningJobs } = useStream(store.state);
   const { credits, dataState } = useStream(creditStore.state);
   const { t } = useTranslation();

   if (dataState === dataStates.loading && isNil(credits)) {
      return <LoadingIndicator size="xxsmall" slot="content" />;
   }

   if (dataState === dataStates.migrating) {
      return <SwwcInformationBar informationText={t('errors.migrationError')} useIcon variant="warning" />;
   }

   if (dataState === dataStates.error) {
      return <SwwcInformationBar informationText={t('errors.creditsGetError')} useIcon variant="error" />;
   }

   return (
      <div className="flex items-center sticky mt-2 z-10">
         <SwwcPropertyValue label={t('credits.available')}>
            <span slot="content">
               <span slot="content">{getBalance(credits.user)}</span>
            </span>
         </SwwcPropertyValue>
         &nbsp;
         <SwwcPopupMenu
            direction="below"
            alignment="left"
            popupTargetTriggerEvent="click"
            style={{ '--swwc-popup-menu-content-width': 'max-content' }}
         >
            <SwwcIcon name="uiDown12" slot="target" className="cursor-pointer" size="md"></SwwcIcon>
            <div slot="content" className="pt-[16px] grid gap-1">
               <SwwcPropertyValue label={t('credits.consumptionRate')}>
                  <span slot="content">{t('credits.consumptionValue', { value: getTotal(runningJobs) })}</span>
               </SwwcPropertyValue>
               <SwwcPropertyValue label={t('credits.totalPool')}>
                  <span slot="content">
                     <span slot="content">{getBalance(credits.account)}</span>
                  </span>
               </SwwcPropertyValue>
               <SwwcLink
                  url={'https://www.dex.siemens.com/plm/simcenter-on-the-cloud'}
                  text={t('commands.buyCredits')}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
               />
            </div>
         </SwwcPopupMenu>
      </div>
   );
});
