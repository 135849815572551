import { compose, mergeDeepLeft } from 'ramda';

import { createApp } from '@/store/common/pal';
import { jobTypesStore } from '@/store/jobtypes/store';

import { actions } from './actions';
import { model } from './model';
import {
   allowSubmission,
   handleComputeTypeUpdate,
   handlePrecisionUpdate,
   trimExtension,
   updateJobNameRequired,
} from './utils';

const nap = ({ proposedState, currentState }) => {
   //Update the job name if a correct simFile has been updated while the name is empty
   if (updateJobNameRequired(currentState, proposedState)) {
      return mergeDeepLeft({ name: trimExtension(proposedState.simulationFile) }, proposedState);
   }

   //When the version has been changed, check to update precision/compute type if not supported by new version
   if (currentState.version.selected !== proposedState.version.selected) {
      const { applications } = jobTypesStore.state();

      const updatedState = compose(
         handlePrecisionUpdate(applications),
         handleComputeTypeUpdate(applications),
      )(proposedState);

      return mergeDeepLeft({ canSubmit: allowSubmission(updatedState) }, updatedState);
   }

   return mergeDeepLeft({ canSubmit: allowSubmission(proposedState) }, proposedState);
};

export const hpcSubmissionStore = createApp(actions, model, nap);
