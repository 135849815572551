// @ts-nocheck
import { path } from 'ramda';

import { isAutomatedTest } from './utils/constants';

const getAnalyticsEnv = path(['accessTokenData', 'sws.samauth.tiers', 0, 'analyticsEnv']);
const getUserId = path(['accessTokenData', 'user', 'userId']);
const getTenantId = path(['accessTokenData', 'tenant', 'tenantId']);
const getEcaId = path(['accessTokenData', 'tenant', 'ecaId']);

export function openWalkme() {
   const { WalkMePlayerAPI } = window;
   if (WalkMePlayerAPI?.isMenuOpen() === false) {
      WalkMePlayerAPI.toggleMenu();
   }
}

export function initWalkme(auth) {
   if (!isAutomatedTest) {
      // Walkme requires this global to be set in order to initialize its data.
      window.CLOUDHPC = {
         analyticsEnv: getAnalyticsEnv(auth),
         userId: getUserId(auth),
         tenantId: getTenantId(auth),
         ecaId: getEcaId(auth),
      };
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = import.meta.env.VITE_WALKME_URL;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(walkme, s);
      // eslint-disable-next-line no-underscore-dangle
      window._walkmeConfig = { smartload: true };
   }
}
