import { isNil, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { useStream } from '@/store/useStream';

import { handleRadioLabel, isVersionDisabled, updateValue } from '@/utils/jobSubmission';

import { SwwcRadioButton, SwwcRadioGroup } from '@/swwc';

export function VersionSelector({ version, applications }) {
   const { t } = useTranslation();
   const state = useStream(hpcSubmissionStore.state);
   const versionError = path(['errors', 'version'], state);

   return (
      <SwwcRadioGroup orientation="vertical" variant="default" label={t('labels.version')}>
         {applications.map(({ marketingVersion }) => {
            const label = handleRadioLabel(version.file)(marketingVersion);
            return (
               <SwwcRadioButton
                  key={marketingVersion}
                  name="versionRadioGroup"
                  label={
                     isNil(label)
                        ? marketingVersion
                        : t(label, { version: marketingVersion, fileVersion: version.file })
                  }
                  value={marketingVersion}
                  disabled={versionError || isVersionDisabled(marketingVersion, version.file)}
                  checked={marketingVersion === version.selected}
                  onCheckedChange={updateValue(hpcSubmissionStore.actions.setVersion)}
               />
            );
         })}
      </SwwcRadioGroup>
   );
}
