import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { QuickActions } from '@/modules/listing/components/QuickActions';

import { isStatusDeleting, isStatusTerminating } from '@/utils/comparators';

/**
 * QuickActionsCell component for rendering either quick actions or a loading indicator.
 *
 * This component conditionally renders either a LoadingIndicator or QuickActions
 * based on the current status of the job.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.job - The job object containing status information.
 * @returns {JSX.Element} Either a LoadingIndicator or QuickActions component.
 *
 * @example
 * <QuickActionsCell job={jobData} />
 */
const QuickActionsCell = ({ job }) => {
   if (isStatusDeleting(job) || isStatusTerminating(job)) {
      return <LoadingIndicator size="xsmall" />;
   }

   return <QuickActions job={job} />;
};

export default QuickActionsCell;
