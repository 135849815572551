import { NavigationButton } from './components/NavigationButton';
import { Pages } from './components/Pages';

export const Pagination = ({ table }) => {
   /**
    * table page index is 0 based compared to page number display, hence the +1/-1
    */
   const page = table.getState().pagination.pageIndex + 1;
   const totalPages = table.getPageCount();
   const onPageChange = (n) => table.setPageIndex(n - 1);

   return (
      <ul className="flex gap-2">
         <li key="back-pagination-button">
            <NavigationButton
               disabled={!table.getCanPreviousPage()}
               direction="left"
               onClick={() => table.previousPage()}
            />
         </li>
         <Pages page={page} totalPages={totalPages} onPageChange={onPageChange} />
         <li key="forward-pagination-button">
            <NavigationButton disabled={!table.getCanNextPage()} direction="right" onClick={() => table.nextPage()} />
         </li>
      </ul>
   );
};
