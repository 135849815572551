import { compose, prop, when } from 'ramda';

import { fileStore } from '@/store/files/store';

import { getFileKey } from '@/utils/embeddedUtils';

const embeddedMessagePublisher = (messageType, payload) => {
   window.dispatchEvent(new CustomEvent(messageType, { detail: payload }));
};

export const addEmbeddedMessageHandler = (messageType, listener) => {
   window.addEventListener(messageType, listener);
};

export const sendEmbeddedMessage = (function () {
   if (window.sendEmbeddedMessage) {
      window.sendEmbeddedMessage('initialize', { publisher: embeddedMessagePublisher });

      // Message Handler for DownloadStatus
      addEmbeddedMessageHandler(
         'fileTransfer/downloadStatus',
         when(
            prop('detail'),
            compose(
               ({ filename, repositoryId, ...info }) =>
                  fileStore.actions.setDownloadInfo(getFileKey(filename, repositoryId), info),
               JSON.parse,
               prop('detail'),
            ),
         ),
      );

      // Message Handler for OverallDownloadStatus
      addEmbeddedMessageHandler(
         'fileTransfer/overallDownloadStatus',
         when(
            prop('detail'),
            compose(
               ({ repositoryId, ...info }) => fileStore.actions.setOverallDownloadInfo(repositoryId, info),
               JSON.parse,
               prop('detail'),
            ),
         ),
      );

      // Message Handler for DownloadPath
      addEmbeddedMessageHandler(
         'fileTransfer/updateDownloadPath',
         when(
            prop('detail'),
            compose(
               ({ repositoryId, downloadPath }) => fileStore.actions.storeDownloadPath(repositoryId, downloadPath),
               JSON.parse,
               prop('detail'),
            ),
         ),
      );

      return window.sendEmbeddedMessage;
   }

   return function () {};
})();

export const isEmbedded = () => {
   return window.sendEmbeddedMessage !== undefined;
};
