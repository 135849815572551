import hpcJobIcon from '/img/hpcJob.svg';
import remoteDesktopJobIcon from '/img/remoteDesktopJob.svg';

export const hpcJobTemplate = {
   key: 'cloud_hpc',
   id: 'hpcJob',
   imageSrc: hpcJobIcon,
   dataCy: 'newCloudHPCJob',
};

export const remoteDesktopTemplate = {
   key: 'remote_desktop',
   id: 'remoteDesktop',
   imageSrc: remoteDesktopJobIcon,
   dataCy: 'newRemoteDesktop',
};

export const projectTemplates = [hpcJobTemplate, remoteDesktopTemplate];
