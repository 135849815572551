import { always, cond, equals, gt, lt, T, when } from 'ramda';

import { isComplete, isFailed, isStatusTerminating, isTerminated } from '@/utils/comparators';
import { stepperTenses } from '@/utils/constants';

export const getLastStepLabel = cond([
   [isComplete, always('complete')],
   [isFailed, always('failed')],
   [isStatusTerminating, always('terminating')],
   [isTerminated, always('terminated')],
   [T, always('finished')],
]);

export const getStepLabel = (job) => when(equals('finished'), () => getLastStepLabel(job));

export const getStepTenses = cond([
   [lt, always(stepperTenses.past)],
   [gt, always(stepperTenses.future)],
   [T, always(stepperTenses.present)],
]);
