import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

/**
 * VersionCell component for rendering version information.
 *
 * This component displays the version and precision of an application.
 * If no application data is provided or the version is unknown, it displays an "unknown version" message.
 *
 * @param {Object} props - The component props.
 * @param {Object|null} props.app - The application object containing version information.
 * @param {string} props.app.version - The version of the application.
 * @param {string} props.app.precision - The precision of the application.
 * @param {Object} props.mappings - An object mapping version strings to their rendered values.
 * @returns {string} The rendered version string or an "unknown version" message.
 */
const VersionCell = ({ app, mappings }) => {
   const { t } = useTranslation();

   if (!app) {
      return t('labels.unknownVersion');
   }

   const { version, precision } = app;
   const renderedVersion = prop(version, mappings) ?? 'unknown';

   return renderedVersion === 'unknown'
      ? t('labels.unknownVersion')
      : t('labels.renderedVersion', {
           version: renderedVersion,
           precision: t(`precision.${precision}`),
        });
};

export default VersionCell;
