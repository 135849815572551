import { includes, mergeLeft } from 'ramda';

import { fileStore } from '@/store/files/store';

import { computeTaskUnifiedStatus } from '@/utils/constants';

export const nap = ({ actions, currentState, proposedState, context }) => {
   // We will use the nap to trigger another fetch if the logs are polling, but they didn't change
   // in this case it will not scroll so we intervene and trigger another fetch.
   // In order to avoid endlessly fetching, we limit the fetch to trigger only when autoScroll is true (i.e.
   // scrolled to the bottom of the logs) and when the task is running
   if (proposedState.timeoutId) {
      clearTimeout(proposedState.timeoutId);
   }

   if (
      context &&
      currentState.logsHeight === proposedState.logsHeight &&
      proposedState.autoScroll &&
      includes(context.task.unifiedStatus, [computeTaskUnifiedStatus.Complete, computeTaskUnifiedStatus.Executing]) &&
      fileStore.state().fileRepository.output.status !== 'READY'
   ) {
      const timeoutId = setTimeout(() => actions.fetchLastLines(context.client, context.task), 5000);
      return mergeLeft({ timeoutId }, proposedState);
   }

   return mergeLeft({ timeoutId: null }, proposedState);
};
