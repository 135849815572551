import { createApp, defaultNap } from '@/store/common/pal';

import { dataStates } from '@/utils/constants';

import { rawActions } from './actions';

export const initialFeatureFlags = {
   userFeatureFlags: [],
   dataState: dataStates.loading,
};

export const featureFlagsStore = createApp(rawActions, initialFeatureFlags, defaultNap);
