import { always, compose, ifElse, isEmpty, join, lte, paths, prepend, prop, test } from 'ramda';

import { forbiddenContent } from './constants';

const joinErrorValues = compose(join(' '), prepend('Error'), paths([['statusCode'], ['response', 'statusText']]));

const isError5xx = compose(lte(500), prop('statusCode'));

export const setMessage = ifElse(
   isError5xx,
   always('An unexpected error was returned by the server.'),
   joinErrorValues,
);

export const nameConstraints = [isEmpty, test(forbiddenContent)];
