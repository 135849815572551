import { gql } from '@urql/core';

export default gql`
   mutation IssueFrsFileRepositoryDownloadTickets($input: IssueFrsFileRepositoryTicketsInput!) {
      issueFrsFileRepositoryDownloadTickets(input: $input) {
         serviceUrl
         bucket
         bucketRegion
         accessKey
         secretAccessKey
         sessionToken
         expiration
         prefix
      }
   }
`;
