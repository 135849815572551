import { compose, prop } from 'ramda';

import { fileStore } from '@/store/files/store';

export const getFileKey = (filename, repositoryId) => filename + repositoryId;

const getFileDownloadInfo = (file, repositoryId) =>
   fileStore.state().downloadInfo[getFileKey(file.filename, repositoryId)];

export const getDownloadStatus = compose(prop('status'), getFileDownloadInfo);

export const getDownloadProgress = compose(prop('progress'), getFileDownloadInfo);
