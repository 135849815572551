import { jwtDecode } from 'jwt-decode';
import {
   compose,
   curry,
   evolve,
   identity,
   join,
   lift,
   map,
   mergeLeft,
   multiply,
   objOf,
   path,
   pick,
   prop,
   toPairs,
   tryCatch,
} from 'ramda';

import { isNotNil } from '@/utils/comparators';

import { retrieveVerifier } from './pkce';
import { authStore } from './store';

const parseSearchParams = (x) => new URLSearchParams(x);
export const getCodeFromLocation = compose(objOf('code'), (x) => x.get('code'), parseSearchParams, prop('search'));
const getSearchParams = compose(evolve({ state: JSON.parse }), Object.fromEntries, parseSearchParams, prop('search'));
const getOriginFromLocation = pick(['origin']);

export const getOriginAndQuery = lift(mergeLeft)(getOriginFromLocation)(getSearchParams);

export const codeIsNotNil = compose(isNotNil, prop('code'));
export const verifierAsObj = compose(mergeLeft, objOf('verifier'), retrieveVerifier);

export const qsFromObject = compose(join('&'), map(join('=')), map(map(encodeURIComponent)), toPairs);

// Note this gives NaN if .accessTokenData.exp does not exist in the given object / is not a valid number
const getExpiry = compose(parseInt, path(['accessTokenData', 'exp']));
export const getExpiryMs = compose(multiply(1000), getExpiry);

export const decodeToken = (data) => {
   const accessTokenData = jwtDecode(data.access_token);
   const refreshTokenData = jwtDecode(data.refresh_token);
   return {
      ...data,
      accessToken: data.access_token,
      idToken: data.id_token,
      refreshToken: data.refresh_token,
      refreshTokenExp: getExpiry({ accessTokenData: refreshTokenData }),
      accessTokenExp: getExpiry({ accessTokenData }),
      accessTokenData,
      idTokenData: jwtDecode(data.id_token),
   };
};

// The number of ms to remove from the token expiry date to be sure we refresh *before* the expiry.
export const REFRESH_DIFF = 1000 * 60 * 15; // 15min

export const scheduleRefresh = curry((fn, data) => {
   const expiresIn = getExpiryMs(data) - Date.now();
   if (!Number.isNaN(expiresIn)) {
      return setTimeout(fn, expiresIn - REFRESH_DIFF);
   }
   return null;
});

export const getAppServerUrlFromState = compose(prop('appServerUrl'), authStore.state);
export const getNiceDCVUrlFromState = compose(prop('niceDCVUrl'), authStore.state);
export const getApiUrlFromState = compose(
   (url) => `${url}/${import.meta.env.VITE_GQL_SUFFIX}`,
   getAppServerUrlFromState,
);
export const appServerFromStateParam = compose(prop('appserver'), tryCatch(JSON.parse, identity));
