import { useTranslation } from 'react-i18next';

import { Section } from './Section';

const privacyPolicyLink = 'https://www.sw.siemens.com/en-US/privacy-policy/';

export function PrivacyPolicy() {
   const { t } = useTranslation();
   return (
      <Section label={t('telemetry.pn.label')}>
         <p className="relative">
            {t('telemetry.pn.info')}{' '}
            <a
               target="_blank"
               rel="noopener noreferrer"
               href={privacyPolicyLink}
               className="font-bold text-status-blue"
            >
               {t('telemetry.pn.link')}
            </a>
         </p>
      </Section>
   );
}
