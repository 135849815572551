import { setMessage } from './errors';

class GQLError extends Error {
   constructor(type, ...errors) {
      super(setMessage(errors[0]?.networkError));
      this.type = type;
      this.errors = errors;
   }
}

class CustomError extends Error {
   constructor(type, message) {
      super(message);
      this.name = type;
      this.type = type;
   }
}
export { GQLError, CustomError };
