import { propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { jobIcons } from '@/utils/constants';

const statusIconOrFallback = propOr(jobIcons.FAILED);

export const StatusIcon = ({ status }) => {
   const { t } = useTranslation();
   const statusText = t(`status.${status}`);

   return <img src={statusIconOrFallback(status, jobIcons)} className="w-6" alt={statusText} title={statusText} />;
};
