import { always, cond, equals, T } from 'ramda';

import { getAppServerUrlFromState } from '@/store/auth/utils';
import { actionsModalStore } from '@/store/modal/store';
import { getWmcUrl } from '@/store/settings/store';

import { getTaskId } from '@/utils/accessors';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { removeTrailingSlash } from '@/utils/formatters';

import { trackOpenRemote, trackOpenWMC } from '@/analytics/events';
import { refinerTrackRemoteDesktop } from '@/analytics/refiner';

export const buildWmcUrl = (job) => {
   // The api URL should be without the https:// prefix for the WMC connection
   const apiHost = getAppServerUrlFromState().replace(import.meta.env.VITE_APP_SERVER_PREFIX, '');

   // WMC does not like it if the whole query param is safely encoded - so we only encode the job ID
   const params = [`taskId=${encodeURIComponent(getTaskId(job))}`, 'target=/monitor', `appserver=${apiHost}`].join('&');
   return `${getWmcUrl()}?${params}`;
   // URL should be like
   // https://cloud-int.swi.siemens.com/simcenter-cloud-hpc/wmc?taskId=am1%2BZTZkNjQxNjktNzkxOC00NzY2LWIwYTYtNDVjNWE4YzI2MGI1fENvbXB1dGVUYXNrfjA4MjJhMzQ4Y2I5ZTRjNjVhMjY2MjVjZGMxODNjY2M5&target=/monitor&appserver=appserver-main-stable-dev.us-1-dev-a.schpc.sws.siemens.com
};

export const onMonitor = (job) => {
   const url = buildWmcUrl(job);

   if (sendEmbeddedMessage('openWMC', { url, jobName: job.name }) === undefined) {
      trackOpenWMC(window.San, job.resourceId);
      window.open(url, '_blank', 'noopener,noreferrer');
   }
};

export const buildJobActionsClickHandler = (job) => {
   const onDelete = () => actionsModalStore.actions.open([job], 'delete');
   const onTerminate = () => actionsModalStore.actions.open([job], 'terminate');
   const onConnect = () => {
      trackOpenRemote(window.San, job.resourceId);
      refinerTrackRemoteDesktop();
      const sessionWindow = window.open(
         `${window.location.origin}${removeTrailingSlash(import.meta.env.BASE_URL)}/remoteDesktop/${job.resourceId}/session`,
         '_blank',
      );
      sessionWindow.simcenterXJobName = job.name;
   };

   return cond([
      [equals('delete'), onDelete],
      [equals('terminate'), onTerminate],
      [equals('monitor'), () => onMonitor(job)],
      [equals('connect'), onConnect],
      [T, always(null)],
   ]);
};
