import { PrivacyPolicy } from './PrivacyPolicy';
import { ProductExcellenceProgram } from './ProductExcellenceProgram';

export function Access() {
   return (
      <>
         <ProductExcellenceProgram isDisclaimer={true} />
         <PrivacyPolicy />
      </>
   );
}
