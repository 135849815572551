import { equals } from 'ramda';

import { fileStore } from '@/store/files/store';

import { isEmbedded } from '@/utils/embeddedMessage';
import { silentDownload } from '@/utils/filesUtils';

import { SwwcCheckbox } from '@/swwc';

import { Cell } from './Cell';
import { DownloadButton } from './DownloadButton';

/**
 * @component
 * Renders a cell with a checkbox and download button for a file
 * @param {Object} props - The component props
 * @param {Object} props.cell - The cell object from react-table
 * @param {Function} props.onSelect - Function to handle file selection
 * @param {Array} props.selected - Array of currently selected files
 * @param {Function} props.onCancel - Function to handle download cancellation
 * @param {Function} props.onPause - Function to handle download pause
 * @param {Function} props.onResume - Function to handle download resume
 * @param {string} props.repositoryId - The ID of the repository
 * @param {Object} props.client - The client object
 * @param {Object} props.downloadPath - Object containing download paths for repositories
 * @returns {JSX.Element} The rendered DownloadCell component
 */

export function DownloadCell({
   cell,
   onSelect,
   selected,
   onCancel,
   onPause,
   onResume,
   repositoryId,
   client,
   downloadPath,
}) {
   const file = cell.row.original;

   const checkSelected = (file) => selected.some(equals(file));

   return (
      <Cell key={cell.id} textStyle="gap-2" cellStyle={isEmbedded() ? 'basis-[110px]' : 'basis-[70px]'}>
         <SwwcCheckbox onCheckChanged={onSelect(file)} checked={checkSelected(file)} id={file.filename} />
         <DownloadButton
            file={file}
            repositoryId={repositoryId}
            downloader={fileStore.actions.downloadFiles(client, silentDownload)}
            onCancel={onCancel}
            onPause={onPause}
            onResume={onResume}
            client={client}
            downloadPath={downloadPath}
         />
      </Cell>
   );
}
