import { updateTrimmedValue, updateValue } from '@/utils/jobSubmission';

import { SwwcInputField } from '@/swwc';

export const TextInput = ({ label, placeholder, onChange, inputValue, errorMessage, error }) => {
   return (
      <SwwcInputField
         label={label}
         placeholder={placeholder}
         name="name"
         type="text"
         value={inputValue}
         required
         errorMessage={errorMessage}
         error={error}
         onValueChange={updateValue(onChange)}
         onBlur={updateTrimmedValue(onChange)}
         uncontrolled-mode
      />
   );
};
