import { gql } from '@urql/core';

export default gql`
   query Job($resourceId: ID!) {
      job(resourceId: $resourceId) {
         tasks {
            name
            inputFileRepository {
               id
               urn
            }
            outputFileRepository {
               id
               urn
            }
         }
         jobDefinition {
            computeType {
               name
            }
         }
      }
   }
`;
