import { isNil, isNotNil, pathOr } from 'ramda';

export const getSettings = (ls) => JSON.parse(ls.getItem('userSettings'));
export const getUpdatedEntitlement = pathOr({}, ['data', 'updateUserEntitlementData']);

export const makeUpdateEntitlementInput = (entitlementDataId, pepTelemetryEnabled, telemetryEnabled) => {
   if (isNotNil(pepTelemetryEnabled) && isNil(telemetryEnabled)) {
      return { entitlementDataId, pepTelemetryEnabled };
   }

   if (isNotNil(telemetryEnabled) && isNil(pepTelemetryEnabled)) {
      return { entitlementDataId, telemetryEnabled };
   }

   return { entitlementDataId };
};
