import { drop, dropLast } from 'ramda';

import { MAXERRORSLENGTH } from '@/utils/constants';

import store from './state';

const updateErrorsState = (error) => {
   store.app.update(({ errors }) => {
      const history = dropLast(errors.length - MAXERRORSLENGTH + 1, errors);
      return {
         ...store.app.state(),
         errors: [error, ...history],
      };
   });
};

const clearLastError = () =>
   store.app.update(({ errors }) => ({
      ...store.app.state(),
      errors: drop(1, errors),
   }));

const errors = {
   clearLastError,
   updateErrorsState,
};

export { errors };
