import { useTranslation } from 'react-i18next';

import { AppsButtons } from '@/modules/common/AppsButtons';
import { Credits } from '@/modules/credits/Credits';

export const AppsList = () => {
   const { t } = useTranslation();

   return (
      <div data-cy="empty-job-list">
         <div className="flex justify-end mr-4">
            <Credits />
         </div>
         <div className="text-center flex flex-col gap-4 mt-20">
            <h2 className="text-4xl font-semibold">{t('common.newProject')}</h2>
            <h3 className="text-xl italic">{t('common.createNew')}</h3>
            <div className="flex justify-center flex-wrap gap-4">
               <AppsButtons />
            </div>
         </div>
      </div>
   );
};
