import { useTranslation } from 'react-i18next';

import { formattedTime } from '@/utils/formatters';

/**
 * Renders the formatted submission time for a job.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.submissionTime - The submission time in ISO format.
 * @returns {string} Translated or formatted time as a string.
 */
const SubmittedTimeCell = ({ submissionTime }) => {
   const { t } = useTranslation();
   const timeFormatted = formattedTime(submissionTime, new Date());

   if (timeFormatted.translationKey) {
      return t(`job.${timeFormatted.translationKey}`, { time: timeFormatted.time });
   }

   return timeFormatted.time;
};

export default SubmittedTimeCell;
