import React from 'react';

import { isImage } from '@/utils/filesUtils';

import { Cell } from './Cell';
import { ImageCell } from './ImageCell';

/**
 * @component
 * FileNameCell component for rendering the filename column in the file table
 * @param {Object} props - Component props
 * @param {Object} props.cell - Cell instance from react-table
 * @returns {React.ReactElement} - A cell component for the filename columns
 */
export function FileNameCell({ cell }) {
   const { filename } = cell.row.original;

   return isImage(filename) ? (
      <ImageCell data-accessor={cell.column.columnDef.header} file={cell.row.original} />
   ) : (
      <Cell data-accessor={cell.column.columnDef.header}>{filename}</Cell>
   );
}
