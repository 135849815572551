import { flexRender } from '@tanstack/react-table';

import { fileTableHeaders } from '@/utils/constants';

import { Cell } from './Cell';
import { DownloadCell } from './DownloadCell';
import { FileNameCell } from './FileNameCell';

/**
 * @component
 * Renders a table row with cells for file information and download controls
 * @param {Object} props - The component props
 * @param {Object} props.row - The row object from react-table
 * @param {Function} props.onSelect - Function to handle file selection
 * @param {Array} props.selected - Array of currently selected files
 * @param {Function} props.onCancel - Function to handle download cancellation
 * @param {Function} props.onPause - Function to handle download pause
 * @param {Function} props.onResume - Function to handle download resume
 * @param {Object} props.client - The client object
 * @param {string} props.repositoryId - The ID of the repository
 * @param {Object} props.downloadPath - Object containing download paths for repositories
 * @returns {JSX.Element} The rendered TableRow component
 */

export function TableRow({ row, onSelect, selected, onCancel, onPause, onResume, client, repositoryId, downloadPath }) {
   return (
      <tr key={row.id}>
         {row.getVisibleCells().map((cell) => {
            if (cell.column.columnDef.header === fileTableHeaders.download.label) {
               return (
                  <DownloadCell
                     key={cell.id}
                     cell={cell}
                     onSelect={onSelect}
                     selected={selected}
                     onCancel={onCancel}
                     onPause={onPause}
                     onResume={onResume}
                     repositoryId={repositoryId}
                     client={client}
                     downloadPath={downloadPath}
                  />
               );
            }
            if (cell.column.columnDef.header === fileTableHeaders.filename.label) {
               return <FileNameCell key={cell.id} cell={cell} />;
            }
            return (
               <Cell key={cell.id} data-accessor={cell.column.columnDef.header}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
               </Cell>
            );
         })}
      </tr>
   );
}
