/* eslint-disable camelcase */
export const model = {
   access_token: '',
   accessToken: '',
   accessTokenData: null,
   accessTokenExp: 0,
   appServerUrl: '',
   niceDCVUrl: '',
   expires_in: 0,
   idToken: '',
   id_token: '',
   idTokenData: null,
   refreshToken: '',
   refresh_token: '',
   refreshTokenExp: 0,
   scope: '',
   token_type: '',
};
