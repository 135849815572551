import { dataStates } from '@/utils/constants';

export const model = {
   productInformation: null,
   task: {},
   submissionTemplate: {
      id: '',
      name: '',
      description: '',
      hourlyCreditRate: 0,
   },
   dataState: dataStates.ready,
   error: null,
};
