import { __, compose, either, find, isNotNil, prop, propEq } from 'ramda';

import { dataStates, fileDescriptions, imageExtensionRegex } from '@/utils/constants';

// Not using cond and match ramda function as it does not work with
// the reg exp we have there (to be investigated)
export const getFileType = (filename) => {
   if (filename.match(fileDescriptions.sim.extension)) {
      return fileDescriptions.sim.type;
   } else if (filename.match(fileDescriptions.macro.extension)) {
      return fileDescriptions.macro.type;
   } else if (filename.match(fileDescriptions.log.extension)) {
      return fileDescriptions.log.type;
   } else if (filename.match(fileDescriptions.image.extension)) {
      return fileDescriptions.image.type;
   }
   return undefined;
};

export const findFileByName = compose(find, propEq(__, 'filename'));

export const isImage = (filename) => imageExtensionRegex.test(filename);
export const hasImageOrLoadingPreview = either(propEq(dataStates.loading, 'state'), compose(isNotNil, prop('image')));

export const silentDownload = (url) => {
   const iframe = document.createElement('iframe');
   iframe.src = url;
   iframe.style.display = 'none';
   document.body.appendChild(iframe);
};
