// @ts-nocheck
import { compose, head, mergeLeft, objOf, prop } from 'ramda';

export const setTemplate = (present) =>
   compose(
      present,
      mergeLeft({ op: 'replace', path: '/form/submissionTemplate/value' }),
      objOf('value'),
      head,
      prop('remote_desktop'),
   );
