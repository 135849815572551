import { gql } from '@urql/core';

export default gql`
   query GetWorkspace {
      workspace {
         id
         jobs: multiversion_jobs {
            id
            resourceId
            name
            status
            submissionTime
            tasks {
               id
               name
               unifiedStatus
            }
            debitReport {
               amount
               runTime
            }
            jobDefinition {
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
               jobType
            }
         }
      }
   }
`;
