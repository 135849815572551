// @ts-nocheck

/* eslint-disable complexity */
import { compose, includes, isNil, unless } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useClient } from 'urql';

import { creditStore } from '@/store/credits/state';
import { jobDetailsStore } from '@/store/hpcJob/store';
import { hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { jobTypesStore } from '@/store/jobtypes/store';
import { hpcJobTemplate } from '@/store/projects/constants';
import { useStream } from '@/store/useStream';

import { CreditsHandler } from '@/modules/common/CreditsHandler';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { TextInput } from '@/modules/common/TextInput';
import { FileInput } from '@/modules/common/fileInput/FileInput';
import { MultipleFilesInput } from '@/modules/common/fileInput/MultipleFilesInput';
import { Credits } from '@/modules/credits/Credits';

import { dataStates, fileExtensions, validSTARCCMVersion } from '@/utils/constants';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { findComputeTypeByName } from '@/utils/jobSubmission';
import { getTimeSpentInSeconds } from '@/utils/utils';

import { SwwcButton } from '@/swwc';

import { ComputeTypes } from './ComputeTypes';
import { VersionAndPrecision } from './VersionAndPrecision';

export const SubmissionForm = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const client = useClient();

   const state = useStream(hpcSubmissionStore.state);
   const jobTypes = useStream(jobTypesStore.state);
   const { hasPositiveEffectiveBalance } = useStream(creditStore.state);

   const [isSubmitting, setIsSubmitting] = useState(false);

   const onCancel = () => navigate('/');
   const submissionIsDisabled = !(state.canSubmit && hasPositiveEffectiveBalance);

   // Analytics variable to calculate time spent to fill the form before submitting it
   const timerStart = useMemo(() => Date.now(), []);

   useEffect(() => {
      jobTypes.dataState === dataStates.ready && hpcSubmissionStore.actions.setInitialTemplate(jobTypes.templates);

      if (jobTypes.dataState === dataStates.ready) {
         sendEmbeddedMessage('populateSimFileField', { cssSelector: "input[name='simulationFile']" });
      }

      return hpcSubmissionStore.actions.clearForm;
   }, [jobTypes.dataState]);

   const handleSubmit = () => {
      setIsSubmitting(true);

      jobDetailsStore.actions
         .runJobWorkflow(client, state, jobTypes.applications.cloud_hpc, getTimeSpentInSeconds(timerStart, Date.now()))
         .then((job) => {
            if (includes(hpcJobTemplate.id, window.location.pathname)) {
               navigate(job.resourceId);
            }
         });
   };

   const handleChange = compose(
      unless(isNil, hpcSubmissionStore.actions.changeFormValue('submissionTemplate')),
      findComputeTypeByName(jobTypes.templates?.cloud_hpc),
   );

   const hourlyCreditRate = state.submissionTemplate?.hourlyCreditRate ?? 0;

   return (
      <div>
         <div className="flex justify-end mr-4">
            <Credits />
         </div>
         <form className="p-4 max-w-screen-md m-auto lg:shadow-md">
            <div className="flex flex-col gap-4">
               <span className="text-lg font-bold" slot="content">
                  {t('common.newCHPCJob')}
               </span>
               <div className="flex gap-4 sm:gap-12 flex-col sm:flex-row w-full">
                  <div className="flex flex-col gap-4 max-w-md sm:w-[60%]">
                     <TextInput
                        label={t('job.name')}
                        placeholder={t('job.namePlaceholder')}
                        onChange={hpcSubmissionStore.actions.changeFormValue('name')}
                        inputValue={state.name}
                        error={state.errors.name}
                        errorMessage={t('errors.hpcJobSubmission.name')}
                     />
                     <FileInput
                        name="simulationFile"
                        label={t('job.simulationFile')}
                        required
                        onChange={hpcSubmissionStore.actions.changeSimulationFile(
                           jobTypes.applications?.cloud_hpc,
                           state.version.selected,
                        )}
                        value={state.simulationFile}
                        error={state.errors.simulationFile}
                        errorMessage={
                           state.errors.simulationFile?.key === 'invalidVersion'
                              ? t('errors.hpcJobSubmission.invalidVersion', {
                                   fileVersion: state.errors.simulationFile.version,
                                   latestSupportedVersion:
                                      state.errors.simulationFile.latestSupported ?? validSTARCCMVersion,
                                })
                              : t('errors.hpcJobSubmission.singleFileUpload', {
                                   extension: fileExtensions.simulationFile,
                                })
                        }
                        extension=".sim"
                     />
                     <FileInput
                        name="javaMacro"
                        label={t('job.mainMacro')}
                        onChange={hpcSubmissionStore.actions.changeFormValue('macro')}
                        value={state.macro}
                        error={state.errors.macro}
                        errorMessage={t('errors.hpcJobSubmission.singleFileUpload', {
                           extension: fileExtensions.macro,
                        })}
                        extension=".java"
                     />
                     <MultipleFilesInput
                        name="otherFiles"
                        label={t('job.otherFiles')}
                        error={state.errors.otherFiles}
                        errorMessage={t('errors.hpcJobSubmission.multipleFileUpload')}
                        onChange={hpcSubmissionStore.actions.changeFormValue('otherFiles')}
                        value={state.otherFiles}
                        multiple
                     />
                  </div>
                  <div className="w-full max-w-md">
                     <ComputeTypes
                        computeTypes={jobTypes.templates?.cloud_hpc}
                        onChange={handleChange}
                        selected={state.submissionTemplate}
                        version={state.version.selected}
                        applications={jobTypes.applications?.cloud_hpc}
                     />
                     <VersionAndPrecision
                        version={state.version}
                        dataState={jobTypes.dataState}
                        applications={jobTypes.applications?.cloud_hpc}
                        selectedPrecision={state.precision.selected}
                     />
                  </div>
               </div>
               <div className="flex flex-col sm:items-end">
                  <div className="h-6 pb-6">
                     <CreditsHandler hourlyCreditRate={hourlyCreditRate} type={t('jobTypeName.cloud_hpc')} />
                  </div>
                  <div className="flex gap-4">
                     <SwwcButton label={t('commands.cancel')} size="md" onClick={onCancel} />
                     {isSubmitting ? (
                        <span className="block w-20 px-2">
                           <LoadingIndicator size="small" />
                        </span>
                     ) : (
                        <SwwcButton
                           label={t('commands.submit')}
                           disabled={submissionIsDisabled}
                           variant="accent-high-contrast"
                           size="md"
                           onClick={handleSubmit}
                        />
                     )}
                  </div>
               </div>
            </div>
         </form>
      </div>
   );
};
