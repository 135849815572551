import { curry } from 'ramda';

import { AUTH_STORAGE_KEY } from '@/utils/constants';

import { model } from './model';

export const actions = (present) => {
   return {
      setAuthData: curry((ls, data) => {
         // Merge update - we do not set the data in localstorage here, but
         // instead leave it for the nap, as we want to set the data _after_
         // it has been merged to the existing data
         present(data, { ls });
      }),

      clearAuthData: (ls, clearState = true) => {
         clearState && present({ op: 'replace', value: model, path: '' });
         ls.removeItem(AUTH_STORAGE_KEY);
      },
   };
};
