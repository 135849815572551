import { gql } from '@urql/core';

export default gql`
   mutation TerminateJob($input: IdInput!) {
      terminateJob(input: $input) {
         job {
            id
            status
            tasks {
               id
               name
               status
               unifiedStatus
               application
            }
            jobDefinition {
               jobType
               applications {
                  version
                  precision
               }
            }
         }
      }
   }
`;
