/**
 * @component
 * Reusable Cell component for table cells
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child elements to render inside the cell
 * @param {string} [props.textStyle=''] - Additional text styles
 * @param {string} [props.cellStyle=''] - Additional cell styles
 * @returns {React.ReactElement} A table cell component
 */
export const Cell = ({ children, textStyle = '', cellStyle = '', ...props }) => (
   <td {...props} className={`cursor-pointer break-all ${cellStyle}`}>
      <span className={`flex items-center ${textStyle}`}>{children}</span>
   </td>
);
