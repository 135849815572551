import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { dataStates } from '@/utils/constants';
import { getMessageKey } from '@/utils/jobSubmission';

import { SwwcInformationBar } from '@/swwc';

import { PrecisionSelector } from './PrecisionSelector';
import { VersionSelector } from './VersionSelector';

export function VersionAndPrecision({ version, dataState, applications, selectedPrecision }) {
   const { t } = useTranslation();

   if (dataState === dataStates.loading) {
      return <LoadingIndicator size="small" />;
   }

   const supportedPrecisions = getTemplateByMarketingVersion(version.selected)(applications)?.precision;
   const messageKey = getMessageKey(version.selected)(version.file);
   return (
      <div>
         <h3 className="font-bold mb-2">{t('labels.productInformation')}</h3>
         {messageKey ? (
            <SwwcInformationBar
               className="mb-2"
               closable={false}
               label=""
               buttonText=""
               fullWidthMode={false}
               informationText={t(messageKey)}
               useIcon={false}
               variant="info"
            />
         ) : (
            messageKey
         )}
         <VersionSelector version={version} applications={applications} />
         <PrecisionSelector
            selectedPrecision={selectedPrecision}
            supportedPrecisions={supportedPrecisions}
            selectedVersion={version.selected}
         />
      </div>
   );
}
