import { on } from 'flyd';
import { useEffect, useState } from 'react';

export const useStream = (stream) => {
   const [current, setCurrent] = useState(stream());

   useEffect(() => {
      const s = on(setCurrent, stream);
      return () => {
         s.end(true);
      };
   }, [stream]);

   return current;
};
