import { either } from 'ramda';

import { isComplete, isFailed, isOutputRepoReady, isTerminated } from '@/utils/comparators';
import { lineHeight } from '@/utils/constants';

export const scrollEventToUpdate = (target) => ({
   offset: Math.round(target.scrollTop / lineHeight),
   // The scrollIntoView function to pull us to the bottom is always short by 2 pixels
   atBottom: target.scrollHeight - target.scrollTop <= target.clientHeight + 2,
   container: target,
});

const completedConstraint = (status) => (res) => isComplete({ status }) && isOutputRepoReady(res);
const terminatedOrFailedConstraints = (status) => () => either(isFailed, isTerminated)({ status });

export const stopFileRepositoryPolling = (status) =>
   either(completedConstraint(status), terminatedOrFailedConstraints(status));
