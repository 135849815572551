import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { store } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { Radio } from './Radio';
import { Section } from './Section';

const link = 'https://www.sw.siemens.com/en-US/trust-center/product-excellence-program/';

export function ProductExcellenceProgram({ isDisclaimer = false }) {
   const client = useClient();
   const { t } = useTranslation();
   const { userEntitlementData, telemetry } = useStream(store.state);

   return (
      <Section label={t('telemetry.pep.label')} className="whitespace-pre-line">
         <p>{t('telemetry.pep.info')}</p>
         <br />
         <p className="relative">
            {t('telemetry.pep.trustCenter')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={link} className="font-bold text-status-blue">
               {t('telemetry.trustCenterLink')}
            </a>
         </p>
         <br />
         <p>{isDisclaimer ? t('telemetry.pep.settings') : t('telemetry.pep.consent')}</p>
         <Radio
            prefix="pep"
            onAgreed={() => store.actions.updateProductExcellenceProgram(client, userEntitlementData.id, true)}
            onDecline={() => store.actions.updateProductExcellenceProgram(client, userEntitlementData.id, false)}
            agreed={telemetry.viewedTelemetry ? telemetry.pepTelemetryEnabled : null}
         />
      </Section>
   );
}
